import { formatDate } from '@angular/common';
import { Component, HostListener } from '@angular/core';
/**
 * This component is the default homepage of Application 360 when the user is not logged in.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  /**
   * Load the current year for the footer.
   */
  currentYear = formatDate(new Date(), 'yyyy', 'en-US', '+0530');
  /**
   * Initiate the navbar class. This is to change the class when the page scrolls.
   */
  navbarClass: string = 'navbar-default';

  /**
   * Get the scroll element from HTML
   * @param el HTML element to manage the page scroll behaviour.
   */
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * Instantiate the hostListener to know the amount of page scroll. According to the scroll the class changes.
   */
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number <= 50) {
      this.navbarClass = 'navbar-default';
    } else {
      this.navbarClass = 'navbar-white';
    }
  }

}
