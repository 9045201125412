import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-combined-view',
  templateUrl: './combined-view.component.html',
  styleUrls: ['./combined-view.component.scss']
})
export class CombinedViewComponent implements OnInit {

  @Input() chartData: Array<Object> = [];
  @Input() widgetHeight: string = '';
  @Input() rowLayoutId: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
