import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-access-request',
    templateUrl: './access-request.component.html',
    styleUrls: ['./access-request.component.scss']
})
export class AccessRequestComponent implements OnInit {

    accessReqForm: FormGroup;
    callInProgress: boolean = false;
    submitted: boolean = false;
    showOkButton: boolean = false;
    errorMessage: string = '';

    constructor(
        private router: Router,
        private formBuild: FormBuilder,
        private apiService: ApiCallService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.accessReqForm = this.formBuild.group({
            email: [this.authService.getCurrentUser().email],
            name: [this.authService.getCurrentUser().fullName == null ? this.authService.getCurrentUser().email : this.authService.getCurrentUser().fullName],
            requestText: ['', [Validators.required]]
        });
    }

    get f() { return this.accessReqForm.controls; }

    onSubmit() {
        this.submitted = true;
        if (this.accessReqForm.invalid) {
            return;
        }
        this.callInProgress = true;
        this.apiService.post('/api/v1/user/createaccess', this.accessReqForm.getRawValue()).subscribe(
            resp => {
                this.callInProgress = false;
                this.errorMessage = resp;
                this.showOkButton = true;
            },
            error => {
                this.errorMessage = "Connect Error. Error Code: " + error.status;
                this.callInProgress = false;
            }
        );
    }

    redirectToLogin() {
        this.authService.logout(true);
        this.router.navigate(["/login"]);
    }

}
