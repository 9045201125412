import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeSetterService {

  private theme: BehaviorSubject<string> = new BehaviorSubject<string>("theme-blue");

  constructor() { }

  getThemeName() {
    return this.theme.asObservable();
  }

  setThemeName(themeName: string) {
    this.theme.next(themeName);
  }
}
