<div class="wrapper">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light navbar-fixed-top" [ngClass]="navbarClass" role="navigation">
      <div class="container">
        <a class="navbar-brand" (click)="scroll(main)"><img src="assets/img/logo-dark.png" alt="Application 360" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
          </ul>
          <ul class="navbar-nav my-2 my-lg-0">
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(main)">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(about)">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(benifits)">Benefits</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(funfacts)">Fun Facts</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(otherOfferings)">Other Offerings</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/login">Login</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <!-- Main Section -->
  <div class="main" #main>
    <div class="main-section">
      <div class="container">
        <div class="main-content text-center">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <h1>APPLICATION 360</h1>
                    <p>
                      IMPROVING APPLICATION LIFE CYCLE EXPERIENCE THROUGH ENGINEERING & INTEGRATION
                    </p>
                  </div>
                  <div class="carousel-item">
                    <h1>INTEGRATED PLATFORM</h1>
                    <p>
                      FOR END-TO-END APP LIFE CYCLE AND PROGRAM MANAGEMENT WITH AGILE & DEVOPS BEST PRACTICES, IN A
                      PLUG-N-PLAY WAY
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-section text-center" #about>
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="col-md-8">
          <div class="about-content">
            <h1>About</h1>
            <p>
              Be at the forefront of modern methods, tools, techniques and frameworks for accelerating and building
              scalable enterprise applications.
            </p>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <div class="about">
            <div class="row">
              <div class="col-sm-3">
                <div class="about-icon">
                  <img src="assets/img/logos/logo1.png" height="60" width="60" alt="About" />
                </div>
                <div class="about-description">
                  <h1>Focused on Agile & DevOps initiatives</h1>
                  <p>
                    Bringing fragmented SDLC processes under a hood for full traceability from portfolio-level EPICs to
                    development-level tasks hence providing all stakeholders the contextual information they need to
                    connect the dots.
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="about-icon">
                  <img src="assets/img/logos/logo2.png" height="60" width="60" alt="About" />
                </div>
                <div class="about-description">
                  <h1>Modern App Development - a click away</h1>
                  <p>
                    Building systems still in traditional ways? Unleash the full potential of your team with Application
                    360 - engage them where their efforts are best suited for. Let Application 360 take care of platform
                    provisioning with code accelerators and DevOps pipeline.
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="about-icon">
                  <img src="assets/img/logos/logo3.png" height="60" width="60" alt="About" />
                </div>
                <div class="about-description">
                  <h1>Build Faster with Confidence</h1>
                  <p>
                    Avoid risk and develop code that adds value to your client-faster and safer. Enable Auto-Deploy or
                    release features when you're ready.
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="about-icon">
                  <img src="assets/img/logos/logo4.png" height="60" width="60" alt="About" />
                </div>
                <div class="about-description">
                  <h1>Bridge Gaps between Engg and Mgmt teams</h1>
                  <p>
                    Starting a project is easy, but managing it is hard. While the engineering teams aspire for agility,
                    management is focused on strategy and standardization. Application 360 tries to balance both the
                    worlds- It reduces the development effort and aids in improving the productivity & quality aspects
                    of people and processes while providing 360 drgree view to various stakeholders and helps foreseeing
                    various risks to & mitigate / track to closer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-features" #benifits>
    <div class="container">
      <h1>Benefits</h1>
      <div class="flex-split">
        <div class="f-left">
          <div class="left-content">
            <img class="img-fluid"
              src="https://colorlib.com//polygon/adminty/files/extra-pages/landingpage/assets/images/feature_1.png"
              alt="" />
          </div>
        </div>
        <div class="f-right">
          <div class="right-content">
            <h2>High performance and flexible code</h2>
            <p>
              App360 is full flexible solution for your entire project development. You can easily maintain all of
              its module/components.
            </p>
            <ul>
              <li><i class="far fa-check-square"></i>Neat n clean code structure.</li>
              <li><i class="far fa-check-square"></i>Flexible module structure</li>
              <li><i class="far fa-check-square"></i>Copy / Paste and Ready to use</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex-split">
        <div class="f-right">
          <div class="right-content">
            <h2>Included Software Dependencies</h2>
            <p>
              Bower - Grunt - Sass Dependencies for easy project flow management.
            </p>
            <ul>
              <li><i class="far fa-check-square"></i>Grunt - No need to update plugins manually</li>
              <li><i class="far fa-check-square"></i>Grunt - Less work you have to performance</li>
              <li><i class="far fa-check-square"></i>Sass - Most Powerful CSS extension language</li>
            </ul>
          </div>
        </div>
        <div class="f-left">
          <div class="left-content wow fadeInUp" data-wow-delay="0.3s">
            <img class="img-fluid"
              src="https://colorlib.com//polygon/adminty/files/extra-pages/landingpage/assets/images/feature_1.png"
              alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="counter-section" #funfacts>
    <div class="container">
      <h1>Fun Facts</h1>
      <div class="row text-center">
        <div class="col-6 col-md-2 offset-1">
          <div class="counter-up">
            <div class="counter-icon">
              <i class="fas fa-users"></i>
            </div>
            <h3><span class="counter">5</span></h3>
            <div class="counter-text">
              <h4>Customer</h4>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="counter-up">
            <div class="counter-icon">
              <i class="far fa-file-code"></i>
            </div>
            <h3><span class="counter">14</span></h3>
            <div class="counter-text">
              <h4>Projects</h4>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="counter-up">
            <div class="counter-icon">
              <i class="fas fa-users"></i>
            </div>
            <h3><span class="counter">85</span></h3>
            <div class="counter-text">
              <h4>Resources</h4>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="counter-up">
            <div class="counter-icon">
              <i class="far fa-clock"></i>
            </div>
            <h3><span class="counter">2480</span></h3>
            <div class="counter-text">
              <h4>Hours Saved</h4>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="counter-up">
            <div class="counter-icon">
              <i class="fas fa-mug-hot"></i>
            </div>
            <h3><span class="counter">121</span></h3>
            <div class="counter-text">
              <h4>Coffee Saved</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pricing-section text-center" #otherOfferings>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="pricing-intro">
            <h1>Other Offerings</h1>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="table-left">
                <div class="pricing-details">
                  <h2>ADvantage-Code</h2>
                  <p>
                    ADvantage-Code is HCL's way for brining productivity in application development via adding
                    automation in development process. Specifically, HCL's approach to platforms based development, how
                    to build an ecosystem that is vendor-agnostic, multi-target supported, and future-proof.
                  </p>
                  <hr>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="table-left">
                <div class="pricing-details">
                  <h2>DRYiCE</h2>
                  <p>
                    The overarching goal of DRYiCE is to achieve operational simplicity. DRYiCE modules can monitor
                    information and take automated actions of varying degrees of complexity based on standard datacenter
                    and network operational processes.
                  </p>
                  <hr>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="table-left">
                <div class="pricing-details">
                  <h2>Cloud Native Development</h2>
                  <p>
                    Enterprises are moving away from cloud ready applications to cloud native applications.
                    Our platform based approach to development brings together application frameworks, platform runtimes
                    and infrastructure automation.
                  </p>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer Section -->
  <div class="footer">
    <div class="row container-fluid">
      <!-- <div class="col-md-12" style="display: inline;"> -->
      <div class="col-md-6 footer-menu">
        <a href="https://www.facebook.com/HCLEnterprise" class="fab fa-facebook-f" matTooltipPosition="above"
          matTooltip="Facebook"></a>
        <a href="https://twitter.com/HCLEnterprise" class="fab fa-twitter" matTooltipPosition="above"
          matTooltip="Twitter"></a>
        <a href="https://www.linkedin.com/company/hcl-enterprise" class="fab fa-linkedin-in" matTooltipPosition="above"
          matTooltip="Linkedin"></a>
        <a href="https://www.youtube.com/user/HCLEnterprise" class="fab fa-youtube" matTooltipPosition="above"
          matTooltip="Youtube"></a>
        <a href="https://www.instagram.com/hcl_enterprise/" class="fab fa-instagram" matTooltip="Instagram"
          matTooltipPosition="above"></a>
      </div>
      <div class="col-md-6 footer-text">
        <p>© Copyright {{currentYear}} HCL. Powered By DNA.</p>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>