import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';
import { environment as env } from '@environments/environment';


@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.scss']
})
export class DetailsModalComponent implements OnInit {

  @Input() targetUrl: string;
  @Output() messageEvent = new EventEmitter<boolean>();

  currentThemeName: String = '';
  targetComponentType: String = '';
  experienceResponse: Object[] = [];
  callInProgress = true;

  constructor(
    private apiService: ApiCallService,
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.callInProgress = true;
    this.apiService.get(this.targetUrl).subscribe(
      resp => {
        if (resp != undefined && resp.data != null && resp.data.length > 0) {
          this.experienceResponse = resp.data;
          this.targetComponentType = this.experienceResponse[0]['widgetType'];
        }
        this.callInProgress = false;
      });
    
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
  }

  closeModal() {
    this.callInProgress = true;
    var element = document.getElementById("maxViewModal");
    element.parentNode.removeChild(element);
    this.messageEvent.emit(true);
  }

}
