import { Component, Input, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call.service';


declare var $: any;

@Component({
    selector: 'app-datatable-rows',
    templateUrl: './datatable-rows.component.html',
    styleUrls: ['./datatable-rows.component.scss']
})
export class DatatableRowsComponent implements OnInit {

    @Input() columnData: string;
    @Input() columnType: string;

    columnValue: any;
    clickValue: string = '';
    loadMoreDetails: boolean = false;

    callUrl: string = '';

    constructor(
        private apiService: ApiCallService
    ) { }

    ngOnInit(): void {
        let splittedVal = this.columnData.split('<~replace~>');
        this.columnValue = splittedVal[0];
        if (splittedVal.length > 1) {
            this.clickValue = splittedVal[1];
        }
    }

    loadItemDetails(targetUrl: string) {
        this.callUrl = targetUrl;
        this.loadMoreDetails = true;
        $('#maxViewModal').appendTo("body").modal('show');
    }


    receiveMessage($event) {
        if ($event) {
            this.callUrl = '';
            this.loadMoreDetails = false;
        }
    }

}
