import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { TypeOneMaxComponent } from './type-one-max/type-one-max.component';
import { TypeOneMinComponent } from './type-one-min/type-one-min.component';
import { TypeTwoMinComponent } from './type-two-min/type-two-min.component';
import { TypeThreeMinComponent } from './type-three-min/type-three-min.component';

@NgModule({
  declarations: [
    TypeOneMaxComponent,
    TypeOneMinComponent,
    TypeTwoMinComponent,
    TypeThreeMinComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CoreModule,
    RoundProgressModule,
    NgxEchartsModule.forRoot({
      echarts,
    })
  ],
  exports: [
    TypeOneMaxComponent,
    TypeOneMinComponent,
    TypeTwoMinComponent,
    TypeThreeMinComponent
  ]
})
export class WidgetsModule { }
