<div [ngClass]="currentThemeName" style="height: 100%;" class="pt-2">
  <div class="table-responsive" style="height: 100%;">
    <table class="table table-nowrap table-fixed">
      <thead *ngIf="chartData[0].enableHeader">
        <tr>
          <th *ngFor="let header of chartData[0].headers" class="text-center">{{header.title}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" *ngFor="let body of chartData[0].data">
          <td *ngFor="let dataField of body">
            <app-progress-card [dataObj]="dataField" *ngIf="dataField.type == 'progressbar'">
            </app-progress-card>
            <app-text-card [dataObj]="dataField" *ngIf="dataField.type == 'stext'">
            </app-text-card>
            <app-round-dial [dataObj]="dataField" *ngIf="dataField.type == 'rdial'">
            </app-round-dial>
            <app-highlight-text class="highlight-text" [dataObj]="dataField" *ngIf="dataField.type == 'htext'">
            </app-highlight-text>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>