import { Component, OnInit, Input } from '@angular/core';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-text-card',
  templateUrl: './text-card.component.html',
  styleUrls: ['./text-card.component.scss']
})
export class TextCardComponent implements OnInit {

  @Input() dataObj: Object;
  projectName: string = '';
  projectOwner: string = '';
  projectStatus: string = '';
  currentThemeName: String = '';

  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    let tmp: Array<string> = this.dataObj['value'].split("<~!~>");
    this.projectName = tmp[0].trim();
    this.projectOwner = tmp[1].trim();
    this.projectStatus = tmp[2].trim();
  }

}
