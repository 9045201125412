import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import html2canvas from '@trainiac/html2canvas';
import { environment as env } from '@environments/environment';
import { AuthService } from 'src/app/core/services/auth.service';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';
import { Widget, PageLayout, RowData } from 'src/app/core/interfaces/page-layout';
import { CustomFiltersService } from 'src/app/core/services/custom-filters.service';
import { SearchData } from 'src/app/core/interfaces/search-result';

declare var $: any;

@Component({
  selector: 'app-type-one-min',
  templateUrl: './type-one-min.component.html',
  styleUrls: ['./type-one-min.component.scss']
})
export class TypeOneMinComponent implements OnInit {

  @Input() rowLayoutId: string;
  @Input() widgetId: number;
  widgetDetail: Widget = {};

  filterSubscription: any;
  currentThemeName: String = '';
  isBackEnabled: boolean = false;
  isProd: boolean = true;
  enableDetailsLink: boolean = env.enableDetailsLink;

  loadDataInterval: any;
  callInProgress: boolean = false;
  globalFilterId: SearchData = {};
  experienceResponse: Array<Object> = [];
  targetComponentType: String = '';
  message: String = '';

  constructor(
    public router: Router,
    private apiService: ApiCallService,
    private authService: AuthService,
    private themeService: ThemeSetterService,
    private filterService: CustomFiltersService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    let pageLayout: Array<PageLayout> = [];
    if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(localStorage.getItem('pageLayout'));
    } else if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(sessionStorage.getItem('pageLayout'));
    }
    let rowData: Array<RowData> = [];
    let currentSelection = this.authService.getCurrentSelection();

    for (let i = 0; i < pageLayout.length; i++) {
      if (pageLayout[i].pageId == +currentSelection.pageId) {
        rowData = pageLayout[i].rowData;
        break;
      }
    }

    let widgetDetailFound: boolean = false;
    for (let j = 0; j < rowData.length; j++) {
      let widgetList: Array<Widget> = rowData[j].widgets;
      for (let i = 0; i < widgetList.length; i++) {
        if (widgetList[i].widgetId == this.widgetId) {
          this.widgetDetail = widgetList[i];
          widgetDetailFound = true;
          break;
        }
      }
      if (widgetDetailFound) {
        break;
      }
    }

    this.loadDataFromUrl();

    if (this.widgetDetail.autoRefreshInterval > 0) {
      this.loadDataInterval = setInterval(() => this.loadDataFromUrl(), this.widgetDetail.autoRefreshInterval * 1000);
    }

    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; })
  }

  loadDataFromUrl() {
    if (this.widgetDetail.minviewDataUrl.endsWith('_PENDING_ID_')) {
      this.targetComponentType = 'ERROR-VIEW';
      this.message = 'This widget is not yet enabled. Please contact Admin.';
    } else {
      this.callInProgress = true;
      if (this.widgetDetail.minviewDataUrl.endsWith('filterId')) {
        this.filterSubscription = this.filterService.getGlobalFilter().subscribe(filter => {
          this.globalFilterId = filter;
          this.callInProgress = true;
          this.apiService.get(this.widgetDetail.minviewDataUrl.replace('filterId', this.globalFilterId.key)).subscribe(
            resp => {
              if (resp != undefined && resp.data != null && resp.data.length > 0) {
                this.experienceResponse = resp.data;
                if (resp.combined) {
                  this.targetComponentType = 'COMBINED-VIEW';
                } else if (this.widgetId == 111 ) {
                    this.targetComponentType = 'TEAM-VIEW';
                  } else {
                  this.targetComponentType = resp.data[0].widgetType;
                }
              } else {
                this.targetComponentType = 'ERROR-VIEW';
                this.message = 'No data found for this Project Metric'
              }
              this.callInProgress = false;
            },
            error => {
              this.targetComponentType = 'ERROR-VIEW';
              this.message = 'Error returned from API (' + error.status + ')';
              this.callInProgress = false;
            });
        });
      } else {
        this.callInProgress = true;
        this.apiService.get(this.widgetDetail.minviewDataUrl).subscribe(
          resp => {
            if (resp != undefined && resp.data != null && resp.data.length > 0) {
              this.experienceResponse = resp.data;
              if (resp.combined) {
                this.targetComponentType = 'COMBINED-VIEW';
              } else if (this.widgetId == 111 ) {
                this.targetComponentType = 'TEAM-VIEW';
              } else {
                this.targetComponentType = resp.data[0].widgetType;
              }
            } else {
              this.targetComponentType = 'ERROR-VIEW';
              this.message = 'No data found for this Project Metric';
            }
            this.callInProgress = false;
          },
          error => {
            this.targetComponentType = 'ERROR-VIEW';
            this.message = 'Error returned from API (' + error.status + ')';
            this.callInProgress = false;
          });
      }
    }
  }

  refreshData() {
    this.loadDataFromUrl();
  }

  stopAutoRefresh(): void {
    clearInterval(this.loadDataInterval);
  }

  expandCollapse() {

  }

  clearWidget() {

  }

  showMaxView(widgetId: number) {
    $('#maxViewModal').modal('show');
  }

  flipCard() {
    this.isBackEnabled = !this.isBackEnabled;
  }

  setPageId(pageId: number) {
    let currentSelection = this.authService.getCurrentSelection();
    this.authService.setCurrentSelection(pageId, currentSelection.aclId);
    this.router.navigate(['/landing']);
  }

  saveAsImage(): void {
    html2canvas(document.querySelector("#widgetContent" + this.widgetDetail.widgetId)).then(canvas => {
      canvas.toBlob(function (blob) {
        let link = document.createElement("a");
        link.download = "image.png";
        link.href = URL.createObjectURL(blob);
        link.click();
      }, 'image/png');
    });
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

}
