import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';

/**
 * Admin guard to protect pages which require only admin access
 */
@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  /**
   * Construct for all the instances of services used in class
   * @param router Instance of router service
   * @param notificationService Instance of notification service
   * @param authService Instance of auth service
   */
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  /**
   * Implementation of canActivate function. In case the user is present and the expiry time is still there plus the role is admin, true is returned else false
   */
  canActivate() {
    const user = this.authService.getCurrentUser();

    if (user && moment().isBefore(user.expiration) && user.role == 'admin') {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
