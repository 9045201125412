import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Acl } from 'src/app/core/interfaces/login-response';
import { AuthService } from 'src/app/core/services/auth.service';
import { PageLayout, RowData } from 'src/app/core/interfaces/page-layout';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { CustomFiltersService } from 'src/app/core/services/custom-filters.service';
import { SearchResult, SearchData } from 'src/app/core/interfaces/search-result';
import { environment as env } from '@environments/environment';

declare var $: any;

/**
 * The dashboard layout. Common page which changes the layout dynamically
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  currentThemeName: String = '';

  layoutName: string = '';
  rowData: Array<RowData> = [];
  pageTitle: string = '';
  isLanding: boolean = false;
  acls: Array<Acl> = [];
  currentProject: Acl = {};

  keyword = 'value';
  globalSearchList: SearchResult = {};
  gid: SearchData = {};

  constructor(
    public router: Router,
    private authService: AuthService,
    private themeService: ThemeSetterService,
    private apiCallService: ApiCallService,
    private filterService: CustomFiltersService
  ) { }

  ngOnInit() {
    let globalSearchUrl: string = 'false';
    let user = this.authService.getCurrentUser()
    this.acls = user.acls[user.currentRole].sort(
      (leftside: number, rightside: number) => {
        if (leftside['id'] < rightside['id']) return -1;
        if (leftside['id'] > rightside['id']) return 1;
        return 0;
      }
    );

    let currentSelection = this.authService.getCurrentSelection();
    this.acls.forEach(acl => {
      if (acl.id == currentSelection.aclId) {
        this.currentProject = acl;
      }
    });

    let pageLayout: Array<PageLayout> = [];
    if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(sessionStorage.getItem('pageLayout'));
    } else if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(localStorage.getItem('pageLayout'));
    }

    for (let i = 0; i < pageLayout.length; i++) {
      if (pageLayout[i].pageId == +currentSelection.pageId) {
        this.rowData = pageLayout[i].rowData;
        let replaceDot = /\./gi;
        let replaceHyphen = /-/gi;
        let replaceUnderscore = /_/gi;
        this.pageTitle = pageLayout[i].pageTitle.replace(replaceUnderscore, ' ').replace(replaceHyphen, ' ').replace(replaceDot, ' ');
        this.isLanding = pageLayout[i].landing;
        globalSearchUrl = pageLayout[i].globalSearchUrl;
        break;
      }
    }

    this.rowData = this.rowData.sort(
      (leftside, rightside): number => {
        if (leftside['order'] < rightside['order']) return -1;
        if (leftside['order'] > rightside['order']) return 1;
        return 0;
      }
    );

    for (let i = 0; i < this.rowData.length; i++) {
      this.rowData[i].widgets = this.rowData[i].widgets.sort(
        (leftside, rightside): number => {
          if (leftside['widgetOrder'] < rightside['widgetOrder']) return -1;
          if (leftside['widgetOrder'] > rightside['widgetOrder']) return 1;
          return 0;
        }
      );
    }

    if (globalSearchUrl != null && globalSearchUrl != '') {
      this.apiCallService.get(globalSearchUrl).subscribe(resp => {
        this.globalSearchList = resp;
        if (this.globalSearchList.data.length > 0) { this.filterService.setGlobalFilter(this.globalSearchList.data[0]); }
      });
    }

    this.filterService.getGlobalFilter().subscribe(resp => { this.gid = resp });

    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; })

  }

  gotoHome() {
    let currentSelection = this.authService.getCurrentSelection();

    let pageLayout: Array<PageLayout> = [];
    if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(sessionStorage.getItem('pageLayout'));
    } else if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(localStorage.getItem('pageLayout'));
    }

    pageLayout = pageLayout.sort(
      (leftside, rightside): number => {
        if (leftside['pageId'] < rightside['pageId']) return 1;
        if (leftside['pageId'] > rightside['pageId']) return -1;
        return 0;
      }
    );

    for (let i = 0; i < pageLayout.length; i++) {
      if (pageLayout[i].landing) {
        this.authService.setCurrentSelection(pageLayout[i].pageId, currentSelection.aclId);
        break;
      }
    }
    this.router.navigate(['/landing']);
  }

  setCurrentProject(projectId: number) {
    let currentSelection = this.authService.getCurrentSelection();
    this.authService.setCurrentSelection(currentSelection.pageId, projectId);
    this.router.navigate(['/landing']);
  }

  setGlobalId(event: any) {
    this.filterService.setGlobalFilter(event);
    // this.router.navigate(['/landing']);
  }

}
