import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomFiltersService {

  private filterValue: BehaviorSubject<Object> = new BehaviorSubject<Object>({});
  
  constructor() { }

  getGlobalFilter() {
    return this.filterValue.asObservable();
  }

  setGlobalFilter(filterValue: Object) {
    this.filterValue.next(filterValue);
  }

}
