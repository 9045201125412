<div class="flip-card" [ngClass]="[currentThemeName, isBackEnabled ? 'active':'']">
  <div class="flip-card-inner">
    <div class="flip-card-front" [id]="'widgetContent' + widgetDetail.widgetId">
      <div class="content-body">
        <app-spinner *ngIf="callInProgress"></app-spinner>
        <app-error-view [eMessage]="message" *ngIf="targetComponentType == 'ERROR-VIEW' && callInProgress == false">
        </app-error-view>
        <!-- <app-pie-doughnut-chart [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          *ngIf="targetComponentType == 'pie' && callInProgress == false">
        </app-pie-doughnut-chart>
        <app-line-bar-chart [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          *ngIf="targetComponentType == 'linebar' && callInProgress == false">
        </app-line-bar-chart>
        <app-data-table-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          *ngIf="targetComponentType == 'datatable' && callInProgress == false">
        </app-data-table-view>
        <app-data-table-image-icon-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          *ngIf="targetComponentType == 'datatable_image' && callInProgress == false">
        </app-data-table-image-icon-view>
        <app-combined-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          [rowLayoutId]="rowLayoutId" *ngIf="targetComponentType == 'COMBINED-VIEW' && callInProgress == false">
        </app-combined-view>
        <app-agile-wallboard [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          *ngIf="targetComponentType == 'AGILE-VIEW' && callInProgress == false">
        </app-agile-wallboard> -->
        <app-cards-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          *ngIf="targetComponentType == 'CARDS-VIEW' && callInProgress == false">
        </app-cards-view>
      </div>

      <div class="shape-right-bottom" (click)="setPageId(widgetDetail.detailPageId)" style="cursor: pointer;"
        *ngIf="widgetDetail.hasDetail && enableDetailsLink">
        <div><i class="fas fa-reply"></i></div>
      </div>
    </div>
  </div>
</div>