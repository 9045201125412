import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DatatableConfig } from 'src/app/core/interfaces/datatable-config';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';
import { AgileWallBoard, BoardDetails, IssueDetails } from 'src/app/core/interfaces/agile-wall-board';

const statusConst: string = 'New<~!~>To Do<~!~>WIP<~!~>Pending Testing<~!~>Pending Review<~!~>Done';

@Component({
  selector: 'app-agile-wallboard',
  templateUrl: './agile-wallboard.component.html',
  styleUrls: ['./agile-wallboard.component.scss']
})
export class AgileWallboardComponent implements OnInit {

  @Input() chartData: Array<DatatableConfig> = [];
  @Input() widgetHeight: string = '';
  currentThemeName: String = '';
  currentPosition: number = 0;
  dtOptions: Object = {};
  drawTable: boolean = false;
  statusArray: Array<string> = [];
  tmpAgileBoard: Array<AgileWallBoard> = [];
  currentChartConfig: AgileWallBoard = {};

  constructor(
    private themeService: ThemeSetterService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });

    let metadataPosition: number = 0;
    for (let i = 0; i < this.chartData.length; i++) {
      if (this.chartData[i].data[0]['Identifier'] == "NA") {
        metadataPosition = i;
        break;
      }
    }
    if (this.chartData[metadataPosition].data[0]['Status'] != null && this.chartData[metadataPosition].data[0]['Status'] != '') {
      this.statusArray = this.chartData[metadataPosition].data[0]['Status'].split('<~!~>');
    } else {
      this.statusArray = statusConst.split('<~!~>');
    }
    this.chartData.splice(metadataPosition, 1);
    if (this.chartData.length > 0) {
      this.chartData.forEach(data => {
        let tmpBoard: AgileWallBoard = {};
        if (data.widgetTitle != null) { tmpBoard.sprintTitle = data.widgetTitle; }
        else { tmpBoard.sprintTitle = 'Sprint Title'; }
        tmpBoard.details = [];

        this.statusArray.forEach(status => {
          let tmpDetails: BoardDetails = {};
          tmpDetails.statusHeader = status;
          tmpDetails.issues = [];
          data.data.forEach(issue => {
            if (issue['Status'].toLowerCase() == status.toLowerCase()) {
              let tmpIssueDetails: IssueDetails = {};
              tmpIssueDetails.issueId = issue['Key'];
              tmpIssueDetails.title = issue['Summary'];
              tmpIssueDetails.storyPoints = issue['Size'];
              tmpIssueDetails.assignedTo = issue['Assignee'];
              tmpIssueDetails.issueType = issue['Type'];
              tmpIssueDetails.priority = issue['Prty'];
              tmpIssueDetails.icon = issue['Icon'];
              tmpDetails.issues.push(tmpIssueDetails);
            }
          });
          tmpBoard.details.push(tmpDetails);
        });
        this.tmpAgileBoard.push(tmpBoard);
      });

      this.setChartContent(0, 'decrease');
    }
  }

  setChartContent(cPosition: number, action: string) {
    this.drawTable = false;
    this.cdr.detectChanges();
    if (this.tmpAgileBoard.length > 1) {
      if (cPosition <= 0) {
        if (action == 'decrease') {
          this.currentPosition = 0;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      } else if (cPosition >= this.tmpAgileBoard.length - 1) {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.tmpAgileBoard.length - 1;
        }
      } else {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      }
      this.currentChartConfig = {};
      this.currentChartConfig = this.tmpAgileBoard[this.currentPosition];
    } else {
      this.currentChartConfig = this.tmpAgileBoard[0];
    }

    let tempTargets: Array<number> = [];
    for (let i = 0; i < this.statusArray.length; i++) {
      tempTargets.push(i);
    }

    let targetColDef: Array<Object> = [];
    targetColDef[0] = {};
    targetColDef[0]['className'] = 'text-center';
    targetColDef[0]['width'] = (100 / this.statusArray.length) + '%';
    targetColDef[0]['targets'] = tempTargets;

    this.dtOptions = {
      paging: false,
      searching: false,
      ordering: false,
      info: false,
      columnDefs: targetColDef,
      fixedHeader: true
    }

    this.drawTable = true;
    this.cdr.detectChanges();
  }

}
