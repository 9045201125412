import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { formatDate } from '@angular/common';
import { ApiCallService } from 'src/app/core/services/api-call.service';
// import { environment as env } from '@environments/environment';
import { PageLayout } from 'src/app/core/interfaces/page-layout';
import jspdf from 'jspdf';
import html2canvas from '@trainiac/html2canvas';
import * as moment from 'moment';
// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';
import { GenericKeyValue } from 'src/app/core/interfaces/generic-key-value';

declare var $: any;

/**
 * Layout component to hold the header and footer for the application
 */
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    timedOut = false;
    currentThemeName: String = '';

    user: any;
    userRoles: Array<string> = [];
    showSidebar: boolean = false;
    userRoleDefinition: GenericKeyValue[] = [];
    currentDateTime: string = formatDate(new Date(), "dd-MMM HH:mm 'IST'", 'en-US', '+0530');
    currentYear: string = formatDate(new Date(), 'yyyy', 'en-US', '+0530');
    currentPageId: number = 0;
    widgetPageList: Array<Object> = [];

    /**
     * Constructs for the service instance used in class
     * @param router Instance for router service
     * @param authService Instance for auth service
     */
    constructor(
        public router: Router,
        private apiService: ApiCallService,
        private authService: AuthService,
        private themeService: ThemeSetterService,
        // private idle: Idle
    ) {
        // idle.setIdle(900);
        // idle.setTimeout(5);
        // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // idle.onTimeout.subscribe(() => {
        //   this.timedOut = true;
        //   this.router.navigate(['/locked']);
        // });

        // this.reset();
    }

    // reset() {
    //     this.idle.watch();
    //     this.timedOut = false;
    // }

    /**
     * on initiate get the current logged in user details
     */
    ngOnInit() {
        this.user = this.authService.getCurrentUser();
        let currentSelection = this.authService.getCurrentSelection();
        this.currentPageId = currentSelection.pageId;
        let pageLayout: Array<PageLayout> = [];
        if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
            pageLayout = JSON.parse(localStorage.getItem('pageLayout'));
        } else if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
            pageLayout = JSON.parse(sessionStorage.getItem('pageLayout'));
        }
        pageLayout = pageLayout.sort(
            (leftside, rightside): number => {
                if (leftside['pageId'] < rightside['pageId']) return -1;
                if (leftside['pageId'] > rightside['pageId']) return 1;
                return 0;
            }
        );

        this.apiService.get('/metadata/roles').subscribe(
            resp => {
                resp.data.forEach(roleDef => {
                    if(roleDef.key != 'AADM' && roleDef.key != 'CADM' && roleDef.key != 'SADM') {
                        this.userRoleDefinition.push(roleDef);
                    }
                });
                // this.userRoleDefinition = resp.data;
            }
        );

        this.userRoles = this.user.role.split(',');
        this.userRoles = this.userRoles.filter(x => x != 'AADM').filter(x => x != 'CADM').filter(x => x != 'SADM')

        pageLayout.forEach(page => {
            let tempObj = {};
            let replaceDot = /\./gi;
            let replaceHyphen = /-/gi;
            let replaceUnderscore = /_/gi;
            tempObj['pageName'] = page.pageTitle.replace(replaceUnderscore, ' ').replace(replaceHyphen, ' ').replace(replaceDot, ' ');
            // tempObj['pageName'] = page.pageTitle.replace('.', ' ');
            tempObj['pageId'] = page.pageId;
            tempObj['pageIcon'] = page.pageIcon;
            let tempWidgetList: Array<String> = [];
            page.rowData.forEach(row => {
                row.widgets.forEach(widget => {
                    tempWidgetList.push(widget.widgetName);
                });
            });
            tempObj['widgetList'] = tempWidgetList;
            this.widgetPageList.push(tempObj);
        });

        this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; })

    }

    setPageId(pageId: number) {
        // let currentSelection = this.authService.getCurrentSelection();
        this.authService.setCurrentSelection(pageId, this.authService.getCurrentSelection().aclId);
        this.router.navigate(['/landing']);
    }

    setTheme(themeName: string) {
        this.themeService.setThemeName(themeName);
    }
    /**
     * logout a current user and redirect to login page
     */
    logout() {
        this.authService.logout(true);
        this.router.navigate(['/login']);
    }

    resetLayout() {
        this.apiService.get('/metadata/layout/page').subscribe(
            resp => {
                sessionStorage.setItem('pageLayout', JSON.stringify(resp.data));
                this.router.navigate(['/landing']);
            }
        ).unsubscribe;
    }

    toggleSidenav() {
        this.showSidebar = !this.showSidebar;
    }

    changeContext(contextId: string) {
        let user = this.authService.getCurrentUser();
        if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
            localStorage.clear();
            localStorage.setItem('currentUser', btoa(JSON.stringify(
                {
                    token: user.token,
                    role: user.role,
                    email: user.email,
                    alias: user.alias,
                    avatar: user.avatar,
                    expiration: user.expiration,
                    fullName: user.fullName,
                    acls: user.acls,
                    currentRole: contextId,
                    userId: user.userId
                })));
        } else if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
            sessionStorage.clear();
            sessionStorage.setItem('currentUser', btoa(JSON.stringify(
                {
                    token: user.token,
                    role: user.role,
                    email: user.email,
                    alias: user.alias,
                    avatar: user.avatar,
                    expiration: user.expiration,
                    fullName: user.fullName,
                    acls: user.acls,
                    currentRole: contextId,
                    userId: user.userId
                })));
        }
        this.router.navigate(['/landing']);
    }

    openPDF(): void {
        // this.isGeneratingPdf = true;
        var data = document.getElementById('main');
        let pdf = new jspdf('l', 'mm', 'a4', true); // A4 size page of PDF
        html2canvas(data).then(canvas => {
            const contentDataURL = canvas.toDataURL('image/png');
            pdf.setFont("Roboto");
            pdf.setFontSize(10);
            pdf.text("This is a system generated dashboard from Application360 at " + moment().format('LLLL') + ". For further support/ details mail to us at dna-app360@hcl.com", 5, 205);
            pdf.addImage(contentDataURL, 'PNG', 5, 5, 285, 190, '', 'FAST');

            pdf.save('App360-dashboard.pdf'); // Generated PDF
            // this.isGeneratingPdf = false;
        });
    }

    showChangePasswordModal() {
        $('#changePasswordModal').modal('show');
    }

    getRoleDefinition(roleKey: string): string {
        let roleDef: string = 'Undefined Role';
        this.userRoleDefinition.forEach(role => {
            if (role.key == roleKey) {
                roleDef = role.value;
            }
        });
        return roleDef;
    }

}
