<div class="row pt-1" [ngClass]="currentThemeName" style="height: 100%;">
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 full pl-0 pr-0 title-block" style="height: 100%;">
    <div class="row ml-auto mr-auto" style="height: 30%; width: 100%;"
      [style.background-color]="leftPanel.backgroundColor" [style.color]="leftPanel.textColor">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <!-- <span class="row title">Overall Score</span> -->
        <span class="row score">{{leftPanel.score}}/{{leftPanel.maxValue}}</span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right">
        <app-round-dial [dataObj]="leftPanelDial"></app-round-dial>
      </div>
    </div>
    <div class="row pt-2 ml-auto mr-auto" style="height: 70%;">
      <div class="col-12 pl-1 pr-1" style="height: 75%;">
        <div echarts [options]="setCurrentChartLine(leftPanel.trendData, leftPanel.chartColor)" class="chart-content"></div>
        <!-- <ngx-trend [data]="leftPanel.trendData" [gradient]="['#FFA000', '#E64A19']" strokeWidth="1"
          [height]="100"></ngx-trend> -->
      </div>
      <div class="col-12 text-center pt-1" style="height: 25%;" *ngIf="leftPanel.hasdetail">
        <button class="btn button-primary-small btn-block ml-auto mr-auto" (click)="setPageId(leftPanel.detailPageId)">
          View Details
        </button>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 full pl-0 pr-0 stats-block" style="height: 100%;" *ngIf="isRightPanelChart == false">
    <div class="row container-fluid ml-0 stats-row" [style.height]="100/rightPanel.length + '%'"
      *ngFor="let data of rightPanel">
      <div class="col-sm-8 col-md-6 pl-1 stat-label" style="height: 100%;">
        <span class="dot" [style.background-color]="data.dotColor"></span>{{data.label}}
      </div>
      <div class="col-sm-4 col-md-3 stat-value" style="height: 100%;">
        <span class="current-value">{{data.currentValue}}</span>
        <div class="change-value pl-1" [style.color]="data.changeColor" [innerHTML]="data.changeValue"></div>
      </div>
      <div class="col-sm-12 col-md-3 pr-2 stat-chart" style="height: 100%;">
        <div echarts [options]="setCurrentChart(data.seriesData, data.seriesColor)" class="chart-content">
        </div>
      </div>
      <!-- <div class="col-sm-2 col-md-2 pl-1 pr-1 mr-0 stat-icon text-center" style="height: 100%;">
        <div *ngIf="data.enableLens"><i class="fas fa-search-plus"></i></div>
      </div> -->
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 full pl-0 pr-0 stats-block" style="height: 100%;"
    *ngIf="isRightPanelChart == true && callInProgress == false">
    <app-line-bar-chart [chartData]="dummyChartData" class="chart-content1" *ngIf="dummyChartData[0].widgetType == 'linebar' && callInProgress == false">
    </app-line-bar-chart>
  </div>
</div>