import { Component } from '@angular/core';

/**
 * Component to load spinner before each page load and http request
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
}
