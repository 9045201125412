import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment as env } from '@environments/environment';
import { NGXLogger } from 'ngx-logger';
import { PageLayout } from 'src/app/core/interfaces/page-layout';

/**
 * Temporary redirecting page to get the current user page layout and store it in session storage
 */
@Component({
    selector: 'app-landing',
    template: '<app-page-spinner></app-page-spinner>'
})
export class LandingComponent implements OnInit {

    /**
     * Constructs of service instances used inside the class
     * @param router Instance of router service
     * @param apiService Instance of api service
     * @param notificationService Instance of notification service
     * @param authService Instance of auth service
     * @param logger Instance of logger service
     */
    constructor(
        private router: Router,
        private apiService: ApiCallService,
        private authService: AuthService,
        private logger: NGXLogger
    ) { }

    /**
     * on initiate get the current user details for page layout and store them in session storage
     */
    ngOnInit() {
        let user = this.authService.getCurrentUser();
        if (user.acls == null || user.acls == undefined || Object.keys(user.acls).length == 0) {
            this.router.navigate(['/access-request']);
        } else {
            if (sessionStorage.getItem('pageLayout') != null) {
                this.setCurrentPageId('SESSION');
                this.logger.debug('On landing page. Setting page layout from SESSION storage');
            } else if (localStorage.getItem('pageLayout') != null) {
                this.setCurrentPageId('LOCAL');
                this.logger.debug('On landing page. Setting page layout from LOCAL storage');
            } else {
                this.logger.debug('On landing page. Pagelayout is not yet set hence fetching from API');
                this.apiService.get('/metadata/layout/page').subscribe(
                    resp => {
                        if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
                            this.logger.debug('On landing page. Storing page layout on SESSION storage.');
                            sessionStorage.setItem('pageLayout', JSON.stringify(resp.data));
                            this.setCurrentPageId('SESSION');
                        } else if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
                            this.logger.debug('On landing page. Storing page layout on LOCAL storage.');
                            localStorage.setItem('pageLayout', JSON.stringify(resp.data));
                            this.setCurrentPageId('LOCAL');
                        }
                    },
                    error => {
                        this.logger.error('On landing page. Error returned from pageLayout API. error message', error.message);
                        this.authService.logout(true);
                        this.router.navigate(["/login"]);
                    }
                );
            }
        }
    }

    setCurrentPageId(storage: string) {

        let user = this.authService.getCurrentUser();
        if (user.acls == null || user.acls == undefined || Object.keys(user.acls).length == 0) {
            this.router.navigate(['/access-request']);
        } else {

            let pageLayout: Array<PageLayout> = [];
            if (storage == 'SESSION') {
                pageLayout = JSON.parse(sessionStorage.getItem('pageLayout'));
            } else if (storage == 'LOCAL') {
                pageLayout = JSON.parse(localStorage.getItem('pageLayout'));
            }

            pageLayout = pageLayout.sort(
                (leftside, rightside): number => {
                    if (leftside['pageId'] < rightside['pageId']) return -1;
                    if (leftside['pageId'] > rightside['pageId']) return 1;
                    return 0;
                }
            );

            let routingUrl: string = 'sui/:pageTitle';

            if (storage == 'SESSION') {
                if (sessionStorage.getItem('currentSelection') == null || sessionStorage.getItem('currentSelection') == undefined) {
                    let defaultAclId: number = 0;

                    let acls = user.acls[user.currentRole].sort(
                        (leftside, rightside): number => {
                            if (leftside['id'] < rightside['id']) return -1;
                            if (leftside['id'] > rightside['id']) return 1;
                            return 0;
                        }
                    );

                    for (let i = 0; i < acls.length; i++) {
                        if (acls[i].default) {
                            defaultAclId = acls[i].id;
                            break;
                        }
                    }

                    for (let i = 0; i < pageLayout.length; i++) {
                        if (pageLayout[i].landing) {
                            this.authService.setCurrentSelection(pageLayout[i].pageId, defaultAclId);
                            routingUrl = routingUrl.replace(':pageTitle', pageLayout[i].pageTitle);
                            break;
                        }
                    }
                } else {
                    let currentSelection = this.authService.getCurrentSelection();
                    for (let i = 0; i < pageLayout.length; i++) {
                        if (pageLayout[i].pageId == +currentSelection.pageId) {
                            routingUrl = routingUrl.replace(':pageTitle', pageLayout[i].pageTitle);
                            break;
                        }
                    }
                }
            } else if (storage == 'LOCAL') {
                if (localStorage.getItem('currentSelection') == null || localStorage.getItem('currentSelection') == undefined) {
                    let defaultAclId: number = 0;
                    let user = this.authService.getCurrentUser();
                    let acls = user.acls[user.currentRole].sort(
                        (leftside, rightside): number => {
                            if (leftside['id'] < rightside['id']) return -1;
                            if (leftside['id'] > rightside['id']) return 1;
                            return 0;
                        }
                    );

                    for (let i = 0; i < acls.length; i++) {
                        if (acls[i].default) {
                            defaultAclId = acls[i].id;
                            break;
                        }
                    }

                    for (let i = 0; i < pageLayout.length; i++) {
                        if (pageLayout[i].landing) {
                            this.authService.setCurrentSelection(pageLayout[i].pageId, defaultAclId);
                            routingUrl = routingUrl.replace(':pageTitle', pageLayout[i].pageTitle);
                            break;
                        }
                    }
                } else {
                    let currentSelection = this.authService.getCurrentSelection();
                    for (let i = 0; i < pageLayout.length; i++) {
                        if (pageLayout[i].pageId == +currentSelection.pageId) {
                            routingUrl = routingUrl.replace(':pageTitle', pageLayout[i].pageTitle);
                            break;
                        }
                    }
                }
            }
            this.router.navigate([routingUrl]);
        }
    }

}
