import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from '@environments/environment';
import { LineBarChartConfig } from 'src/app/core/interfaces/line-bar-chart-config';
import { PageLayout, RowData, Widget } from 'src/app/core/interfaces/page-layout';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

interface CardData {
  borderColor?: string,
  rDialColor?: string,
  rDialValue?: number,
  rDialMaxValue?: number,
  statLabel?: string,
  dotColor?: string,
  trendData?: Array<number>,
  trendColor?: string,
  hasDetail?: boolean,
  targetPageId?: number
}

@Component({
  selector: 'app-type-three-min',
  templateUrl: './type-three-min.component.html',
  styleUrls: ['./type-three-min.component.scss']
})
export class TypeThreeMinComponent implements OnInit {

  cardData: CardData;
  @Input() widgetId: number;
  widgetDetail: Widget = {};

  filterSubscription: any;
  currentThemeName: String = '';
  isBackEnabled: boolean = false;
  isProd: boolean = true;

  callInProgress: boolean = true;
  targetComponentType: String = '';
  message: String = '';

  constructor(
    public router: Router,
    private authService: AuthService,
    private apiService: ApiCallService,
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    if (window.location.href.toLowerCase().indexOf('dev') > 0 
        || window.location.href.toLowerCase().indexOf('test') > 0
        || window.location.href.toLowerCase().indexOf('local') > 0) {
      this.isProd = false;
    }
    let pageLayout: Array<PageLayout> = [];
    if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(localStorage.getItem('pageLayout'));
    } else if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
      pageLayout = JSON.parse(sessionStorage.getItem('pageLayout'));
    }
    let rowData: Array<RowData> = [];
    let currentSelection = this.authService.getCurrentSelection();

    for (let i = 0; i < pageLayout.length; i++) {
      if (pageLayout[i].pageId == +currentSelection.pageId) {
        rowData = pageLayout[i].rowData;
        break;
      }
    }

    let widgetDetailFound: boolean = false;
    for (let j = 0; j < rowData.length; j++) {
      let widgetList: Array<Widget> = rowData[j].widgets;
      for (let i = 0; i < widgetList.length; i++) {
        if (widgetList[i].widgetId == this.widgetId) {
          this.widgetDetail = widgetList[i];
          widgetDetailFound = true;
          break;
        }
      }
      if (widgetDetailFound) {
        break;
      }
    }

    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });

    this.loadDataFromUrl();
  }


  loadDataFromUrl() {
    if (this.widgetDetail.minviewDataUrl.endsWith('_PENDING_ID_')) {
      this.targetComponentType = 'ERROR-VIEW';
      this.message = 'This widget is not yet enabled. Please contact Admin.';
    } else {
      this.callInProgress = true;
      this.apiService.get(this.widgetDetail.minviewDataUrl).subscribe(
        resp => {
          if (resp != undefined && resp.data != null && resp.data.length > 0) {
            this.cardData = resp.data[0].data;
          } else {
            this.targetComponentType = 'ERROR-VIEW';
            this.message = 'No data found for this Project Metric'
          }
          this.callInProgress = false;
        },
        error => {
          this.callInProgress = false;
        }).unsubscribe;
    }
  }

  setPageId(pageId: number) {
    if (this.cardData.hasDetail) {
      this.authService.setCurrentSelection(pageId, 1);
      this.router.navigate(['/landing']);
    }
  }

  setCurrentChartLine(chartData: Array<number>, chartColor: string) {
    let currentChartConfig: LineBarChartConfig = {};
    currentChartConfig.grid = {};
    currentChartConfig.grid.right = 0;
    currentChartConfig.grid.left = 0;
    currentChartConfig.grid.top = 0;
    currentChartConfig.grid.bottom = 0;
    currentChartConfig.grid.show = false;
    currentChartConfig.tooltip = {};
    currentChartConfig.tooltip.backgroundColor = '#fff';
    currentChartConfig.tooltip.borderColor = '#b6c1d2';
    currentChartConfig.tooltip.borderWidth = 1;
    currentChartConfig.tooltip.padding = [7, 10];
    currentChartConfig.tooltip.textStyle = {};
    currentChartConfig.tooltip.textStyle.color = '#5e6e82';
    currentChartConfig.tooltip.textStyle.fontWeight = 'bold';
    currentChartConfig.tooltip.textStyle.fontSize = 11;
    currentChartConfig.tooltip.transitionDuration = 0;
    currentChartConfig.tooltip.triggerOn = "mousemove";
    currentChartConfig.xAxis = [];
    currentChartConfig.xAxis[0] = {};
    currentChartConfig.xAxis[0].data = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];
    currentChartConfig.xAxis[0].show = false;
    currentChartConfig.xAxis[0].splitLine = {};
    currentChartConfig.xAxis[0].splitLine.show = false;
    currentChartConfig.yAxis = [];
    currentChartConfig.yAxis[0] = {};
    currentChartConfig.yAxis[0].show = false;
    currentChartConfig.yAxis[0].splitLine = {};
    currentChartConfig.yAxis[0].splitLine.show = false;
    currentChartConfig.series = [];
    currentChartConfig.series[0] = {};
    currentChartConfig.series[0].type = 'line';
    currentChartConfig.series[0].lineStyle = {
      "width": 3,
      "shadowColor": 'rgba(0,0,0,0.4)',
      "shadowBlur": 10,
      "shadowOffsetY": 10
    };
    currentChartConfig.series[0].areaStyle = {"opacity": 0.2};
    currentChartConfig.series[0].smooth = true;
    currentChartConfig.series[0].color = chartColor;
    currentChartConfig.series[0].data = chartData;

    return currentChartConfig;
  }

}
