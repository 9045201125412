<div [ngClass]="currentThemeName">
	<div class="modal fade custom-modal" id="changePasswordModal">
		<div class="modal-dialog modal-md modal-dialog-centered">
			<div class="modal-content details" style="height: 100%;">
				<div class="modal-header login-header text-center">
					<img [src]="userImage" alt="">
					<h4 class="mt-3">{{userFullname}}</h4>
				</div>
				<div class="modal-body">
					<div class="card-error-notification" *ngIf="!callInProgress && errorMessage != ''">{{errorMessage}}</div>
					<form class="pull-left" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
						<div class="form-group">
							<label>Current Password</label>
							<input type="password" class="form-control" placeholder="Your Current Password" formControlName="currentPass" name="current-password" autocomplete="on"
								[ngClass]="{ 'is-invalid': submitted && f.currentPass.errors}" required>
							<div *ngIf="submitted && f.currentPass.errors" class="invalid-feedback">
								<div *ngIf="f.currentPass.errors.required">Password is required</div>
								<div *ngIf="f.currentPass.errors.minlength">Password should be minimum 8 characters</div>
								<div *ngIf="f.currentPass.errors.maxlength">Password should be maximum 20 characters</div>
							</div>
						</div>
						<div class="form-group">
							<label>New Password</label>
							<input type="password" class="form-control" placeholder="New Password" formControlName="newPass"  name="new-password" autocomplete="on"
								[ngClass]="{ 'is-invalid': submitted && f.newPass.errors}" required>
							<div *ngIf="submitted && f.newPass.errors" class="invalid-feedback">
								<div *ngIf="f.newPass.errors.required">Password is required</div>
								<div *ngIf="f.newPass.errors.minlength">Password should be minimum 8 characters</div>
								<div *ngIf="f.newPass.errors.maxlength">Password should be maximum 20 characters</div>
							</div>
							<app-strength-bar [passwordToCheck]="newPassword"></app-strength-bar>
						</div>      
			
						<div class="form-group">
							<label>Retype New Password</label>
							<input type="password" class="form-control" placeholder="Retype New Password" formControlName="newPassConfirm"  name="new-password" autocomplete="on"
								[ngClass]="{ 'is-invalid': submitted && f.newPassConfirm.errors}" required>
							<div *ngIf="submitted && f.newPassConfirm.errors" class="invalid-feedback">
								<div *ngIf="f.newPassConfirm.errors.required">Password is required</div>
								<div *ngIf="f.newPassConfirm.errors.ConfirmPassword">Password do not match</div>
							</div>
						</div>
			
						<div class="login-footer d-flex">
							<button type="submit" class="btn btn-primary ml-auto mr-3" [disabled]="callInProgress">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="callInProgress"></span>
								Update
							</button>
							<div class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
								Cancel
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
