import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PData } from 'src/app/core/interfaces/card-table-t1';
import { LineBarChartConfig } from 'src/app/core/interfaces/line-bar-chart-config';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-cio-stats',
  templateUrl: './cio-stats.component.html',
  styleUrls: ['./cio-stats.component.scss']
})
export class CioStatsComponent implements OnInit {

  @Input() chartData: Array<Object> = [];
  @Input() widgetHeight: string = '';
  leftPanel: any;
  leftPanelDial: PData = {};
  rightPanel: any;
  currentThemeName: String = '';
  isRightPanelChart: boolean = false;
  dummyChartData: LineBarChartConfig = {};
  callInProgress: boolean = false;
  
  constructor(
    public router: Router,
    private themeService: ThemeSetterService,
    private authService: AuthService,
    private apiCallService: ApiCallService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });

    this.leftPanel = this.chartData[0]['data']['leftPanel'];
    this.rightPanel = this.chartData[0]['data']['rightPanel'];
    if (this.rightPanel[0].dmId != undefined && this.rightPanel[0].dmId != null) {
      this.callInProgress = true;
      this.isRightPanelChart = true;
      this.apiCallService.get(this.rightPanel[0].dmId).subscribe(resp => {
        this.dummyChartData = resp.data;
        this.callInProgress = false;
      }).unsubscribe;
    }
        
    this.leftPanelDial.badgeColor = "badge-grey";
    this.leftPanelDial.badgeValue = 0;
    this.leftPanelDial.label = '';
    this.leftPanelDial.order = 0;
    this.leftPanelDial.percentageValue = true;
    this.leftPanelDial.trendLine = null;
    this.leftPanelDial.type = 'rdial';
    this.leftPanelDial.value = ((this.leftPanel['score'] / this.leftPanel['maxValue']) * 100).toString();
    this.leftPanelDial.valueColor = 'gd_pb3';


  }

  setCurrentChart(chartData: Array<number>, chartColor: string) {
    let currentChartConfig: LineBarChartConfig = {};
    currentChartConfig.grid = {};
    currentChartConfig.grid.right = 0;
    currentChartConfig.grid.left = 0;
    currentChartConfig.grid.top = 0;
    currentChartConfig.grid.bottom = 0;
    currentChartConfig.grid.show = false;
    currentChartConfig.tooltip = {};
    currentChartConfig.tooltip.backgroundColor = '#fff';
    currentChartConfig.tooltip.borderColor = '#b6c1d2';
    currentChartConfig.tooltip.borderWidth = 1;
    currentChartConfig.tooltip.padding = [7, 10];
    currentChartConfig.tooltip.textStyle = {};
    currentChartConfig.tooltip.textStyle.color = '#5e6e82';
    currentChartConfig.tooltip.textStyle.fontWeight = 'bold';
    currentChartConfig.tooltip.textStyle.fontSize = 11;
    currentChartConfig.tooltip.transitionDuration = 0;
    currentChartConfig.tooltip.triggerOn = "mousemove";
    currentChartConfig.xAxis = [];
    currentChartConfig.xAxis[0] = {};
    currentChartConfig.xAxis[0].data = ['P1', 'P2', 'P3', 'P4', 'P5','P6', 'P7', 'P8', 'P9', 'P10'];
    currentChartConfig.xAxis[0].show = false;
    currentChartConfig.xAxis[0].splitLine = {};
    currentChartConfig.xAxis[0].splitLine.show = false;
    currentChartConfig.yAxis = [];
    currentChartConfig.yAxis[0] = {};
    currentChartConfig.yAxis[0].show = false;
    currentChartConfig.yAxis[0].splitLine = {};
    currentChartConfig.yAxis[0].splitLine.show = false;
    currentChartConfig.series = [];
    currentChartConfig.series[0] = {};
    currentChartConfig.series[0].type = 'bar';
    currentChartConfig.series[0].color = chartColor;
    currentChartConfig.series[0].data = chartData;

    return currentChartConfig;
  }

  setCurrentChartLine(chartData: Array<number>, chartColor: string) {
    let currentChartConfig: LineBarChartConfig = {};
    currentChartConfig.grid = {};
    currentChartConfig.grid.right = 0;
    currentChartConfig.grid.left = 0;
    currentChartConfig.grid.top = 0;
    currentChartConfig.grid.bottom = 0;
    currentChartConfig.grid.show = false;
    currentChartConfig.tooltip = {};
    currentChartConfig.tooltip.backgroundColor = '#fff';
    currentChartConfig.tooltip.borderColor = '#b6c1d2';
    currentChartConfig.tooltip.borderWidth = 1;
    currentChartConfig.tooltip.padding = [7, 10];
    currentChartConfig.tooltip.textStyle = {};
    currentChartConfig.tooltip.textStyle.color = '#5e6e82';
    currentChartConfig.tooltip.textStyle.fontWeight = 'bold';
    currentChartConfig.tooltip.textStyle.fontSize = 11;
    currentChartConfig.tooltip.transitionDuration = 0;
    currentChartConfig.tooltip.triggerOn = "mousemove";
    currentChartConfig.xAxis = [];
    currentChartConfig.xAxis[0] = {};
    currentChartConfig.xAxis[0].data = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];
    currentChartConfig.xAxis[0].show = false;
    currentChartConfig.xAxis[0].splitLine = {};
    currentChartConfig.xAxis[0].splitLine.show = false;
    currentChartConfig.yAxis = [];
    currentChartConfig.yAxis[0] = {};
    currentChartConfig.yAxis[0].show = false;
    currentChartConfig.yAxis[0].splitLine = {};
    currentChartConfig.yAxis[0].splitLine.show = false;
    currentChartConfig.series = [];
    currentChartConfig.series[0] = {};
    currentChartConfig.series[0].type = 'line';
    currentChartConfig.series[0].areaStyle = {};
    currentChartConfig.series[0].smooth = true;
    currentChartConfig.series[0].color = chartColor;
    currentChartConfig.series[0].data = chartData;

    return currentChartConfig;
  }

  setPageId(pageId: number) {
    this.authService.setCurrentSelection(pageId, 1);
    this.changeContext('PROJ');
  }

  changeContext(contextId: string) {
    let user = this.authService.getCurrentUser();
    if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('pageLayout');
      localStorage.setItem('currentUser', btoa(JSON.stringify(
        {
          token: user.token,
          role: user.role,
          email: user.email,
          alias: user.alias,
          avatar: user.avatar,
          expiration: user.expiration,
          fullName: user.fullName,
          acls: user.acls,
          currentRole: contextId
        })));
    } else if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('pageLayout');
      sessionStorage.setItem('currentUser', btoa(JSON.stringify(
        {
          token: user.token,
          role: user.role,
          email: user.email,
          alias: user.alias,
          avatar: user.avatar,
          expiration: user.expiration,
          fullName: user.fullName,
          acls: user.acls,
          currentRole: contextId
        })));
    }
    this.router.navigate(['/landing']);
  }

}
