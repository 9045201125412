import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External Libraries
import * as echarts from 'echarts';
import { TrendModule } from 'ngx-trend';
import { ToastrModule } from 'ngx-toastr';
import { NgxEchartsModule } from 'ngx-echarts';
import { DataTablesModule } from 'angular-datatables';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RoundProgressModule } from 'angular-svg-round-progressbar';

// Shared Components
import { LayoutComponent } from './layout/layout.component';
// Loaders
import { SpinnerComponent } from './spinners/widget-spinner/spinner.component';
import { PageSpinnerComponent } from './spinners/page-spinner/page-spinner.component';
// Individual Components
import { PieDoughnutChartComponent } from './main-components/pie-doughnut-chart/pie-doughnut-chart.component';
import { NotificationsCardComponent } from './main-components/notifications-card/notifications-card.component';
import { ErrorViewComponent } from './main-components/error-view/error-view.component';
import { LineBarChartComponent } from './main-components/line-bar-chart/line-bar-chart.component';
import { CombinedViewComponent } from './main-components/combined-view/combined-view.component';
// Small Components
import { TextCardComponent } from './small-components/text-card/text-card.component';
import { RoundDialComponent } from './small-components/round-dial/round-dial.component';
import { LabelTableComponent } from './small-components/label-table/label-table.component';
import { ProgressCardComponent } from './small-components/progress-card/progress-card.component';
import { HighlightTextComponent } from './small-components/highlight-text/highlight-text.component';
import { DataTableViewComponent } from './main-components/data-table-view/data-table-view.component';
import { DataTableImageIconViewComponent } from './main-components/data-table-image-icon-view/data-table-image-icon-view.component';
import { AgileWallboardComponent } from './main-components/agile-wallboard/agile-wallboard.component';
import { CardsViewComponent } from './main-components/cards-view/cards-view.component';
import { BigPictureComponent } from './main-components/big-picture/big-picture.component';
import { TeamViewComponent } from './main-components/team-view/team-view.component';
import { CioStatsComponent } from './main-components/cio-stats/cio-stats.component';
import { CoreModule } from '../core/core.module';
import { DatatableRowsComponent } from './small-components/datatable-rows/datatable-rows.component';
import { DetailsModalComponent } from './small-components/details-modal/details-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './main-components/change-password/change-password.component';
import { StrengthBarComponent } from './main-components/strength-bar/strength-bar.component';

@NgModule({
  declarations: [
    LayoutComponent, 
    SpinnerComponent, 
    PageSpinnerComponent, 
    TextCardComponent, 
    ProgressCardComponent, 
    HighlightTextComponent, 
    RoundDialComponent, 
    LabelTableComponent, 
    NotificationsCardComponent, 
    PieDoughnutChartComponent, 
    ErrorViewComponent, 
    LineBarChartComponent, 
    CombinedViewComponent, 
    DataTableViewComponent, 
    DataTableImageIconViewComponent, 
    AgileWallboardComponent, 
    CardsViewComponent, 
    BigPictureComponent, 
    TeamViewComponent,
    CioStatsComponent, 
    DatatableRowsComponent, 
    DetailsModalComponent,
    ChangePasswordComponent,
    StrengthBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TrendModule,
    BrowserAnimationsModule,
    CoreModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    DataTablesModule,
    ToastrModule.forRoot({
      "closeButton": true,
      "newestOnTop": true,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "preventDuplicates": false,
      "timeOut": 5000,
      "extendedTimeOut": 1000
    }),
    RoundProgressModule,
    NgIdleKeepaliveModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    SpinnerComponent,
    PageSpinnerComponent,
    ProgressCardComponent,
    TextCardComponent,
    HighlightTextComponent,
    RoundDialComponent,
    LabelTableComponent,
    ErrorViewComponent,
    PieDoughnutChartComponent,
    LineBarChartComponent,
    DataTableViewComponent,
    DataTableImageIconViewComponent,
    CombinedViewComponent,
    AgileWallboardComponent,
    CardsViewComponent,
    BigPictureComponent,
    TeamViewComponent,
    CioStatsComponent
  ]
})
export class SharedModule { }
