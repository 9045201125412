import { Component, OnInit, Input } from '@angular/core';
import { CombinedViewData } from 'src/app/core/interfaces/combined-view-component';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-label-table',
  templateUrl: './label-table.component.html',
  styleUrls: ['./label-table.component.scss']
})
export class LabelTableComponent implements OnInit {

  @Input() dataObj: CombinedViewData = {};
  currentThemeName: String = '';
  
  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; })
  }

}
