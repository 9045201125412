import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {

  user: any;
  loginForm: FormGroup;
  /**
   * submitted vaiable to identify when the form is submitted and once true evaluate for errors.
   */
  submitted: boolean = false;
  errorMessage: string = '';

  loginCallInProgress: boolean = false;

  constructor(
    private formBuild: FormBuilder,
    private router: Router,
    private logger: NGXLogger,
    private apiService: ApiCallService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    if (!this.user) {
      this.loginForm = this.formBuild.group({
        userName: [{ value: '', disabled: true }],
        userPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
      });
      this.router.navigate(['/login']);
    } else {
      this.loginForm = this.formBuild.group({
        userName: [{ value: this.user.email, disabled: true }],
        userPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
      });
    }

  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.logger.warn('Invalid login form submitted. Returning the request.');
      return;
    }
    this.loginCallInProgress = true;
    let formObj = this.loginForm.getRawValue();
    this.apiService.post('/api/v3/authenticate', formObj).subscribe(
      resp => {
        if (resp.status == 'ok') {
          this.logger.debug('User successful login hence redirecting to dashboard page.');
          this.router.navigate(['/landing']);
        } else {
          this.errorMessage = resp.message;
          this.loginCallInProgress = false;
        }
      }
    ).unsubscribe;
  }

  logout() {
    this.authService.logout(true);
    this.router.navigate(['/login']);
  }

}
