import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment as env } from '@environments/environment';
import { AuthService } from './auth.service';

/**
 * This service is to call the Rest endpoints on different methods.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  /**
   * This value defines the api timeout in milliseconds.
   */
  private apiTimeout: number = env.apiTimeout;
  /**
   * All constructs are defined in this block.
   * @param _httpClient HttpClient constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private authService: AuthService
  ) { }

  /**
   * Common method to set headers before the actual call.
   */
  private setHeaders() {
    let currentSelection = this.authService.getCurrentSelection();
    let user = this.authService.getCurrentUser();
    let headersConfig = {};
    headersConfig['Content-Type'] = "application/json";
    if (currentSelection != undefined && currentSelection != null && user != null && user != undefined) {
      headersConfig['X-FLTR'] = currentSelection.aclId.toString();
    }
    return new HttpHeaders(headersConfig);
  }

  /**
   * send GET request to the target service
   * @param path The url of the service
   * @param responseType Optional response content-type expected from the server.
   */
   get(path: string, expectedRespType?: "TEXT" | "JSON" | "BLOB", headers?: Headers): Observable<any> {
    if (expectedRespType == "TEXT") {
      return this._httpClient
        .get(path, { headers: this.setHeaders(), responseType: "text" })
        .pipe(
          timeout(this.apiTimeout),
          map(result => result, (error: any) => error)
        );
    } else if (expectedRespType == "BLOB") {
      return this._httpClient
        .get(path, { headers: this.setHeaders(), responseType: "blob" })
        .pipe(
          timeout(this.apiTimeout),
          map(result => result, (error: any) => error)
        );
    }
    return this._httpClient
      .get(path, { headers: this.setHeaders() })
      .pipe(
        timeout(this.apiTimeout),
        map(result => result, (error: any) => error)
      );
  }

  /**
   * Send PUT request to the target service
   * @param path The url of the service
   * @param body Body of the PUT request
   */
  // put(path: string, body: Object = {}): Observable<any> {
  //   return this._httpClient
  //     .put(path, JSON.stringify(body), { headers: this.setHeaders() })
  //     .pipe(
  //       timeout(this.apiTimeout),
  //       map(result => result,
  //         (error: any) => error)
  //     );
  // }

  put(path: string, body: Object = {}, expectedRespType?: "TEXT" | "JSON", headers?: Headers): Observable<any> {
    if (expectedRespType == "TEXT") {
      return this._httpClient
        .put(path, JSON.stringify(body), { headers: this.setHeaders(), responseType: "text" })
        .pipe(
          timeout(this.apiTimeout),
          map(result => result, (error: any) => error)
        );
    }
    return this._httpClient
      .put(path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(
        timeout(this.apiTimeout),
        map(result => result, (error: any) => error)
      );
  }

  /**
   * send POST request to the target service
   * @param path The url of the service
   * @param body Body of the POST request
   */
  post(path: string, body: Object = {},  expectedRespType?: "TEXT" | "JSON"): Observable<any> {
    if (expectedRespType == "TEXT") {
        return this._httpClient
          .post(path, JSON.stringify(body), { headers: this.setHeaders(), responseType: "text" })
          .pipe(
            timeout(this.apiTimeout),
            map(result => result, (error: any) => error)
          );
      }
    return this._httpClient
      .post(path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(
        timeout(this.apiTimeout),
        map(result => result,
          (error: any) => error)
      );
  }

  /**
   * send DELETE request to the target service
   * @param path The url of the service
   */
  delete(path: string): Observable<any> {
    return this._httpClient
      .delete(path, { headers: this.setHeaders() })
      .pipe(
        timeout(this.apiTimeout),
        map(result => result,
          (error: any) => error)
      );
  }
}
