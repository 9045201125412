<div [ngClass]="currentThemeName">
  <div class="modal fade custom-modal" id="maxViewModal">
    <div class="modal-dialog modal-xl modal-dialog-centered" style="height: 80%; width: 90%; max-width: 90%;">
      <div class="modal-content details" style="height: 100%;">
        <div class="d-flex header">
          <div class="ml-auto">
            <ul>
              <li data-dismiss="modal" aria-label="Close" (click)="closeModal()"><i class="fas fa-times"></i>
              </li>
            </ul>
          </div>
        </div>
        <div class="" style="height: 100%;" *ngIf="reloadedSuccess">
          <app-pie-doughnut-chart [chartData]="experienceResponse" *ngIf="targetComponentType == 'pie'">
          </app-pie-doughnut-chart>
          <app-line-bar-chart [chartData]="experienceResponse" *ngIf="targetComponentType == 'linebar'">
          </app-line-bar-chart>
          <app-data-table-view [chartData]="experienceResponse" *ngIf="targetComponentType == 'datatable'">
          </app-data-table-view>
          <app-data-table-image-icon-view [chartData]="experienceResponse" *ngIf="targetComponentType == 'datatable_image'">
          </app-data-table-image-icon-view>
        </div>
      </div>
    </div>
  </div>
</div>