import { Component, OnInit, Input } from '@angular/core';
import { PData } from 'src/app/core/interfaces/card-table-t1';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-round-dial',
  templateUrl: './round-dial.component.html',
  styleUrls: ['./round-dial.component.scss']
})
export class RoundDialComponent implements OnInit {

  @Input() dataObj: PData;
  color: string = '#000000';
  currentThemeName: String = '';

  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    if (this.dataObj.valueColor == 'gd_pb1') {
      this.color = '#f44336';
    } else if (this.dataObj.valueColor == 'gd_pb2') {
      this.color = '#f46c31';
    } else if (this.dataObj.valueColor == 'gd_pb3') {
      this.color = '#f5912c';
    } else if (this.dataObj.valueColor == 'gd_pb4') {
      this.color = '#f5b627';
    } else if (this.dataObj.valueColor == 'gd_pb5') {
      this.color = '#f6db22';
    } else if (this.dataObj.valueColor == 'gd_pb6') {
      this.color = '#f4f53e';
    } else if (this.dataObj.valueColor == 'gd_pb7') {
      this.color = '#cff82c';
    } else if (this.dataObj.valueColor == 'gd_pb8') {
      this.color = '#a6f03c';
    } else if (this.dataObj.valueColor == 'gd_pb9') {
      this.color = '#74e74f';
    } else if (this.dataObj.valueColor == 'gd_pb10') {
      this.color = '#00d27a';
    }
  }

}
