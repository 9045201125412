import { Component, OnInit } from '@angular/core';
/**
 * Full page spinner
 */
@Component({
  selector: 'app-page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss']
})
export class PageSpinnerComponent {
}
