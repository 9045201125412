<div *ngIf="clickValue == '' && (columnType == null || columnType == 'text')" [innerHTML]="columnValue | sanitizeHtml"></div>
<div *ngIf="clickValue == '' && columnType == 'progressbar' && columnValue != ''">
    <div class="w-100">
        <div class="progress " style="height:20px">
          <div class="progress-bar gd_pb8" [ngStyle]="{'width': columnValue + '%'}">
            <span>{{columnValue}}%</span></div>
        </div>
      </div>
</div>
<div *ngIf="clickValue != ''" (click)="loadItemDetails(clickValue)" style="cursor: pointer;">{{columnValue}}</div>

<app-details-modal [targetUrl]="callUrl" *ngIf="loadMoreDetails" (messageEvent)="receiveMessage($event)">
</app-details-modal>