<div class="card card-notification">
  <div class="card-header">
    <div class="align-items-center row">
      <div class="col">
        <h6 class="mb-0">Notifications</h6>
      </div>
      <div class="text-right col-auto">
        <a class="card-link" href="/widgets#!">Mark all as read</a>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ul class="list-group list-group-flush">
      <ng-container *ngFor="let notification of notifications.data">
        <li class="list-group-item" *ngIf="notification.isAlert == false">
          <a class="notification">
            <div class="notification-avatar notification-avatar-1" *ngIf="notification.severity == 1"><span>c</span></div>
            <div class="notification-avatar notification-avatar-2" *ngIf="notification.severity == 2"><span>h</span></div>
            <div class="notification-avatar notification-avatar-3" *ngIf="notification.severity == 3"><span>m</span></div>
            <div class="notification-avatar notification-avatar-4" *ngIf="notification.severity == 4"><span>l</span></div>
            <div class="notification-avatar notification-avatar-5" *ngIf="notification.severity == 5"><span>i</span></div>
            <div class="notification-body">
              <p class="mb-0">{{notification.message}}</p>
              <span class="notification-time">{{notification.timestamp}}</span>
            </div>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="card-footer">
    <div class="d-flex">
      <h6 class="mb-0">Last Updated {{lastUpdateTime}}</h6>
      <a class="ml-auto card-link">View all</a>
    </div>
  </div>
</div>