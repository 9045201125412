<div class="flip-card" [ngClass]="[currentThemeName, isBackEnabled ? 'active':'']">
  <div class="flip-card-inner"  *ngIf="callInProgress == false">
    <div class="flip-card-front" [id]="'widgetContent' + widgetDetail.widgetId"
      [style.border-left]="'8px solid ' + cardData.borderColor">
      <div class="d-flex header">
        <div class="widget-title text-truncate">{{widgetDetail.widgetName}}</div>
      </div>
      <div class="row d-flex card-content" (click)="setPageId(cardData.targetPageId)" style="cursor: pointer;">
        <div class="col-sm-12 col-md-6">
          <div class="progress-wrapper">
            <div class="current-value">{{cardData.rDialValue}}/{{cardData.rDialMaxValue}}</div>
            <round-progress [current]="cardData.rDialValue" [max]="cardData.rDialMaxValue" [color]="cardData.rDialColor" [background]="'#eaeaea'" [radius]="35"
              [stroke]="7" [semicircle]="false" [rounded]="true" [clockwise]="true" [responsive]="true" [duration]="800"
              [animation]="'easeInOutQuart'"></round-progress>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 ml-auto mr-auto text-center">
          <div class="row stat-label ">
            <span class="dot" [style.background-color]="cardData.dotColor"></span>{{cardData.statLabel}}
            <!-- <span class="dot" [style.background-color]="'#F57C00'"></span>Goal : 4 -->
          </div>
          <div class="row">
            <div echarts [options]="setCurrentChartLine(cardData.trendData, cardData.trendColor)" class="chart-content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>