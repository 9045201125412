<div class="flip-card" [ngClass]="[currentThemeName, isBackEnabled ? 'active':'']">
  <div class="flip-card-inner">
    <div class="flip-card-front" [id]="'widgetContent' + widgetDetail.widgetId">
      <div class="d-flex header">
        <div class="widget-title text-truncate"><span *ngIf="isProd == false">({{widgetDetail.widgetId}})
          </span>{{widgetDetail.widgetName}}</div>
        <div class="ml-auto">
          <ul>
            <!-- <li (click)="maxView.loadMaxView(widgetDetail, true)" (click)="showMaxView(widgetDetail.widgetId)" *ngIf="widgetDetail.maxviewDataUrl != ''"><i class="fas fa-info"></i> -->
            <!-- <li (click)="maxView.loadMaxView(widgetDetail, false, experienceResponse)" (click)="showMaxView(widgetDetail.widgetId)" ><i class="fas fa-expand"></i> -->
            <!-- </li> -->
            <li class="dropdown">
              <!-- <div > -->
                <i class="fas fa-cog" data-toggle="dropdown"></i>
                <!-- <i class="fas fa-ellipsis-h" data-toggle="dropdown"></i> -->
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" (click)="refreshData()">Refresh</a>
                  <a class="dropdown-item" (click)="stopAutoRefresh()" *ngIf="widgetDetail.autoRefreshInterval > 0">Stop
                    Auto-refresh</a>
                  <a (click)="flipCard()" class="dropdown-item">Metric Information</a>
                  <!-- <a class="dropdown-item" (click)="expandCollapse()">Expand - Collapse</a> -->
                  <a class="dropdown-item" (click)="saveAsImage()">Save as .png</a>
                  <!-- <div class="dropdown-divider"></div> -->
                  <!-- <a class="dropdown-item text-danger" (click)="clearWidget()">Remove Metric</a> -->
                </div>
              <!-- </div> -->
            </li>
          </ul>
        </div>
      </div>
      <div class="content-body">
        <app-spinner *ngIf="callInProgress"></app-spinner>
        <app-error-view [eMessage]="message" *ngIf="targetComponentType == 'ERROR-VIEW' && callInProgress == false">
        </app-error-view>
        <app-pie-doughnut-chart [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" *ngIf="targetComponentType == 'pie' && callInProgress == false">
        </app-pie-doughnut-chart>
        <app-line-bar-chart [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" *ngIf="targetComponentType == 'linebar' && callInProgress == false">
        </app-line-bar-chart>
        <app-big-picture [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" *ngIf="targetComponentType == 'BigPicute' && callInProgress == false">
        </app-big-picture>
        <app-data-table-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" *ngIf="targetComponentType == 'datatable' && callInProgress == false">
        </app-data-table-view>
        <app-data-table-image-icon-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" *ngIf="targetComponentType == 'datatable_image' && callInProgress == false">
        </app-data-table-image-icon-view>
        <app-combined-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" [rowLayoutId]="rowLayoutId" *ngIf="targetComponentType == 'COMBINED-VIEW' && callInProgress == false">
        </app-combined-view>
        <app-agile-wallboard [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" *ngIf="targetComponentType == 'AgileView' && callInProgress == false">
        </app-agile-wallboard>
        <app-team-view [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight" *ngIf="targetComponentType == 'TEAM-VIEW' && callInProgress == false">
        </app-team-view>
        <app-cio-stats [chartData]="experienceResponse" [widgetHeight]="widgetDetail.widgetHeight"
          *ngIf="targetComponentType == 'ciostat' && callInProgress == false">
        </app-cio-stats>
      </div>

      <div class="shape-right-bottom" (click)="setPageId(widgetDetail.detailPageId)" style="cursor: pointer;"
        *ngIf="widgetDetail.hasDetail && enableDetailsLink">
        <div><i class="fas fa-reply"></i></div>
      </div>
    </div>

    <div class="flip-card-back">
      <div class="heading text-center">{{widgetDetail.widgetName}}</div>
      <div class="heading text-center">Description</div>
      <div class="detail" style="height: 70%;">
        <div [innerHTML]="widgetDetail.shortDescription"></div>
        <div class="detail-url" *ngIf="isProd == false">Config ID - {{widgetDetail.minviewDataUrl}}</div>
      </div>
      <div (click)="flipCard()" class="link" style="cursor: pointer;"><i class="fas fa-reply"></i></div>
    </div>
  </div>
</div>

<!-- <app-type-one-max #maxView [widgetDetail]="widgetDetail" [experienceResponse]="experienceResponse"></app-type-one-max> -->