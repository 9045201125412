import { Component, Input } from '@angular/core';
import { BigPictureDataObject } from 'src/app/core/interfaces/big-picture-data-object';

@Component({
  selector: 'app-highlight-text',
  templateUrl: './highlight-text.component.html',
  styleUrls: ['./highlight-text.component.scss']
})
export class HighlightTextComponent {

  @Input() dataObj: BigPictureDataObject;
  textValue: string = '';
  textLabel: string = '';
  constructor() { }

}
