import { Component, Input, OnInit } from '@angular/core';
import { Widget } from 'src/app/core/interfaces/page-layout';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-type-one-max',
  templateUrl: './type-one-max.component.html',
  styleUrls: ['./type-one-max.component.scss']
})
export class TypeOneMaxComponent implements OnInit {

  @Input() widgetDetail: Widget;
  @Input() experienceResponse: Array<Object>;

  currentThemeName: String = '';
  targetComponentType: String = '';
  reloadedSuccess: boolean = false;

  constructor(
    private apiService: ApiCallService,
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {

    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    // if (this.experienceResponse.length > 0) {
    //   // if (this.experienceResponse.combined) {
    //   //   this.targetComponentType = 'COMBINED-VIEW';
    //   // } else 
    //   if (this.widgetDetail.widgetId == 46 || this.widgetDetail.widgetId == 95) {
    //     this.targetComponentType = 'AGILE-VIEW';
    //   } else if (this.widgetDetail.widgetId == 111) {
    //     this.targetComponentType = 'TEAM-VIEW';
    //   } else {
    //     this.targetComponentType = this.experienceResponse[0]['widgetType'];
    //   }
    // }
  }

  loadMaxView(widgetDetail: Widget, reloadValues: boolean, experienceResponse?: Array<Object>) {
    this.widgetDetail = widgetDetail;
    if (reloadValues == false) {
      this.experienceResponse = experienceResponse;
      if (this.widgetDetail.widgetId == 46 || this.widgetDetail.widgetId == 95) {
        this.targetComponentType = 'AGILE-VIEW';
      } else if (this.widgetDetail.widgetId == 111) {
        this.targetComponentType = 'TEAM-VIEW';
      } else {
        this.targetComponentType = this.experienceResponse[0]['widgetType'];
      }
    } else {
      this.experienceResponse = [];
      this.apiService.get(this.widgetDetail.maxviewDataUrl).subscribe(
        resp => {
          if (resp != undefined && resp.data != null && resp.data.length > 0) {
            this.experienceResponse = resp.data;
            if (resp.combined) {
              this.targetComponentType = 'COMBINED-VIEW';
            } else if (this.widgetDetail.widgetId == 46 || this.widgetDetail.widgetId == 95) {
              this.targetComponentType = 'AGILE-VIEW';
            } else if (this.widgetDetail.widgetId == 111) {
              this.targetComponentType = 'TEAM-VIEW';
            } else {
              this.targetComponentType = resp.data[0].widgetType;
            }
          }
        },
        error => {
          this.targetComponentType = 'ERROR-VIEW';
        });
    }
  }

  closeModal() {
    var element = document.getElementById("maxViewModal");
    element.parentNode.removeChild(element);
  }

}
