import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoggerModule } from 'ngx-logger';
import { CoreModule } from '../core/core.module';
import { HomeComponent } from './home/home.component';
import { LockComponent } from './lock/lock.component';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { LandingComponent } from './landing/landing.component';
import { environment as env } from '@environments/environment';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccessRequestComponent } from './access-request/access-request.component';


@NgModule({
  declarations: [LoginComponent, HomeComponent, DashboardComponent, NotFoundComponent, LandingComponent, LockComponent, AccessRequestComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/logs/ui',
      level: env.logLevel,
      serverLogLevel: env.serverLogLevel
    }),
    AutocompleteLibModule,
    CoreModule,
    SharedModule,
    WidgetsModule
  ]
})
export class PageModule { }
