<div [ngClass]="currentThemeName" style="height: 100%;">
  <div class="row pt-1">
    <div class="chart-title text-center swipe-buttons col-12" *ngIf="currentChartConfig.sprintTitle != 'DEFAULT_GROUP'">
      <a class="control-button" (click)="setChartContent(currentPosition, 'decrease')" *ngIf="chartData.length &gt; 1">
        <i class="fa fa-angle-left"></i>
      </a>
      <span class="pl-2 pr-2">{{currentChartConfig.sprintTitle}}</span>
      <a class="control-button" (click)="setChartContent(currentPosition, 'increase')" *ngIf="chartData.length &gt; 1">
        <i class="fa fa-angle-right"></i>
      </a>
    </div>
  </div>
  <div class="row pt-1" style="height: 100%;">
    <app-error-view class="col-12" style="width: 100%; height: 100%;"
      [eMessage]="'No data found for this Project Metric'" *ngIf="chartData.length == 0"></app-error-view>
    <!-- <div *ngIf="currentChartConfig.length &gt; 0"></div> -->
    <div class="col-12" style="width: 100%; height: 100%; overflow-y: auto;" *ngIf="chartData.length &gt; 0">
      <table datatable [dtOptions]="dtOptions" class="table dtable-format"
        style="width: 100%; height: 100%; overflow-y: auto;" *ngIf="drawTable">
        <thead>
          <tr>
            <th *ngFor="let header of currentChartConfig?.details">{{header.statusHeader}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center">
            <td class="" *ngFor="let body of currentChartConfig?.details">
              <div class="pb-2 pl-1 pr-1 card-container" *ngFor="let issue of body?.issues">
                <div class="card agile-card ml-auto mr-auto" [ngClass]="issue.issueType == 'Story'? 'story':
                issue.issueType == 'Bug'? 'bug':
                issue.issueType == 'Epic'? 'epic':
                issue.issueType == 'Feature'? 'feature':
                issue.issueType == 'Task'? 'task':
                issue.issueType == 'backlog'? 'backlog':'backlog'">
                  <div class="card-body">
                    <span class="title">{{issue.issueId}}</span>
                    <div class="card issue-detail-card">
                      <div class="card-body">
                        <div class="row container-fluid ml-0 mr-0">
                          <div class="title-id">{{issue.issueType}} - {{issue.issueId}}</div>
                          <div class="title-value ml-auto">Size: {{issue.storyPoints}}</div>
                        </div>
                        <div class="row container-fluid ml-0 mr-0 pt-2">
                          <div class="title-text">{{issue.title}}</div>
                        </div>
                        <div class="row container-fluid ml-0 mr-0 pt-2">
                          <div class="col-10 pl-0 pr-0">
                            <div class="title-value"><i class="fas fa-user-circle"></i>{{issue.assignedTo}}</div>
                            <div class="title-value">{{issue.priority}}</div>
                          </div>
                          <div class="col-2 pl-1 pr-0 ml-auto">
                            <div class="title-value " [innerHTML]="issue.icon"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>