import { Component, OnInit } from '@angular/core';
/**
 * Not found page in case there is change in route or there is a mistyped address
 */
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

}
