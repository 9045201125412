import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';
import * as moment from 'moment';

/**
 * This component is to load the login page for Application 360.
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    /**
     * Loginform variable of type FormGroup
     */
    loginForm: FormGroup;
    /**
     * submitted vaiable to identify when the form is submitted and once true evaluate for errors.
     */
    submitted: boolean = false;
    errorMessage: string = '';
    isSocialLogin: boolean = false;

    loginCallInProgress: boolean = false;

    /**
     * Construct instances for various services
     * @param _formBuild FormBuilder module instance
     * @param _router Router module instance
     * @param notificationService Notification Service instance
     * @param logger Logger library instance
     */
    constructor(
        private formBuild: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private logger: NGXLogger,
        private apiService: ApiCallService,
        private authService: AuthService
    ) {
        this.route.queryParams.subscribe(params => {
            this.isSocialLogin = params.socialLogin;
        }
        );
    }

    /**
     * Initialise login form with fields on component init
     */
    ngOnInit() {
        this.loginForm = this.formBuild.group({
            userName: ['', [Validators.required, Validators.email]],
            userPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
            rememberMe: [false]
        });
        const user = this.authService.getCurrentUser();
        if (user && moment().isBefore(user.expiration)) {
            this.logger.info('User already loggedin hence redirecting to dashboard page.');
            this.router.navigate(['/landing']);
        } else {
            this.logger.info('Either Token has expired or user is not logged in hence logging the user out of the system.');
            this.authService.logout(false);
        }

        if(this.isSocialLogin) {
            this.socialLoginRequest();
        }
    }

    /**
     * Function to return the field name.
     */
    get f() { return this.loginForm.controls; }

    /**
     * Function to catch the submit request from the page and call the auth service to perform the login action.
     */
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            this.logger.error('Invalid login form submitted. Returning the request.');
            return;
        }
        this.loginCallInProgress = true;
        let formObj = this.loginForm.getRawValue();
        this.apiService.post('/api/v3/authenticate', formObj).subscribe(
            resp => {
                if (resp.status == 'ok') {
                    this.authService.login(resp.data, formObj['rememberMe']);
                    this.logger.info('User successful login hence redirecting to dashboard page.');
                    this.logger.info('user login success.', formObj['userName']);
                    this.router.navigate(['/landing']);
                } else {
                    this.logger.error('user login error. Error message {}', formObj['userName'], resp.message);
                    this.errorMessage = resp.message;
                    this.loginCallInProgress = false;
                }
            },
            error => {
                this.logger.error('Connect Error. Error Code: ', error.status);
                this.errorMessage = "Connect Error. Error Code: " + error.status;
                this.loginCallInProgress = false;
            }
        );
    }

    openSSOTokenService() {
        window.open('/api/v4/authenticate?redirectToUrl=true&redirectUrl=' + btoa(window.location.href + '?socialLogin=true'), '_self');
    }

    async socialLoginRequest() {
        await delay(3000);
        this.apiService.get('/api/v4/authenticate?redirectToUrl=false').subscribe(
            resp => {
                if (resp.status == 'ok') {
                    this.authService.login(resp.data, false);
                    this.logger.info('User successful login hence redirecting to dashboard page.');
                    this.router.navigate(['/landing']);
                } else {
                    this.logger.error('user login error. Error message {}', resp.message);
                    this.errorMessage = resp.message;
                }
            },
            error => {
                this.logger.error('Connect Error. Error Code: ', error.status);
                this.errorMessage = "Connect Error. Error Code: " + error.status;
            }
        );
    }

}

function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
