<div [ngClass]="currentThemeName" style="height: 100%;">
  <div class="row pt-2" style="height: 100%;">
    <div class="col-12 pl-1 pr-1" style="height: 100%; overflow-y: auto;">
      <table datatable [dtOptions]="dtOptions" class="table dtable-format" style="width: 100%;">
        <tbody>
          <tr *ngFor="let data of dtableWithImage">
            <td>
              <div class="avatar-circle-sm mr-1">
                <span class="initials">{{data.initials}}</span>
              </div>
            </td>
            <td>
              <app-datatable-rows [columnData]="data.fullName"></app-datatable-rows>
              <!-- <div [innerHTML]="data.fullName | sanitizeHtml"></div> -->
              <span>Designation</span>
            </td>
            <td class="count-overview">
              <div>{{data.count}}</div>
              <span>Story Points</span>
              <div class="card details-card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6 pl-1 pr-1 pt-1 mt-2 status-bar" *ngFor="let detail of data.details">
                      <span class="title">{{detail.key}}</span>
                      <span class="value">{{detail?.value}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>