<div [ngClass]="currentThemeName" style="height: 100%;">
  <div class="row pt-1">
    <div class="col-2 swipe-buttons" *ngIf="chartData.length &gt; 1">
      <a class="control-button" (click)="setChartContent(currentPosition, 'decrease')"><i
          class="fa fa-angle-left"></i></a>
    </div>
    <div class="chart-title text-center" [ngClass]="chartData.length &gt; 1? 'col-8':'col-12'"
      *ngIf="currentChartConfig.widgetTitle != 'DEFAULT_GROUP'">
      {{currentChartConfig.widgetTitle}}</div>
    <!-- <div class="col-8 chart-title-transparent text-center" *ngIf="currentChartConfig.widgetTitle == 'DEFAULT_GROUP'">
      {{currentChartConfig.widgetTitle}}</div> -->
    <div class="col-2 swipe-buttons text-right" *ngIf="chartData.length &gt; 1">
      <a class="control-button" (click)="setChartContent(currentPosition, 'increase')"><i
          class="fa fa-angle-right"></i></a>
    </div>
  </div>
  <!-- <div class="row container-fluid">
    <div class="legend-badge" *ngFor="let legend of currentChartConfig?.series">
      <span class="badge" [style.background-color]="legend.itemStyle.color">{{legend.name}}</span>
    </div>
  </div> -->
  <div class="row"
    [style.height]="chartData.length &gt; 1 || currentChartConfig.widgetTitle != 'DEFAULT_GROUP'? '80%':'92%'">
    <div class="col-12 pl-1 pr-1">
      <div echarts [options]="currentChartConfig" class="chart-content">
      </div>
    </div>
  </div>
</div>