<div [ngClass]="currentThemeName" style="height: 100%;">
  <div class="row pt-1">
    <div class="col-2 swipe-buttons" *ngIf="chartData.length &gt; 1">
      <a class="control-button" (click)="setChartContent(currentPosition, 'decrease')"><i
          class="fa fa-angle-left"></i></a>
    </div>
    <div class="chart-title text-center" [ngClass]="chartData.length &gt; 1? 'col-8':'col-12'"
      *ngIf="currentChartConfig.widgetTitle != 'DEFAULT_GROUP'">
      {{currentChartConfig.widgetTitle}}</div>
    <div class="col-8 chart-title-transparent text-center" *ngIf="currentChartConfig.widgetTitle == 'DEFAULT_GROUP'">
      {{currentChartConfig.widgetTitle}}</div>
    <div class="col-2 swipe-buttons text-right" *ngIf="chartData.length &gt; 1">
      <a class="control-button" (click)="setChartContent(currentPosition, 'increase')"><i
          class="fa fa-angle-right"></i></a>
    </div>
  </div>
  <div class="row" style="height: 80%;">
    <div class="col-md-8 col-sm-8 pl-1">
      <div echarts [options]="currentChartConfig" class="chart-content">
      </div>
    </div>
    <div class="col-md-4 col-sm-4 pl-1">
      <ng-container *ngFor="let data of currentChartConfig?.customLegend; let i=index;">
        <div class="row pt-2" *ngIf="i &lt; 4">
          <div class="status-bar" [style.border-left-color]="data?.color">
            <span class="title">{{data?.name}}</span>
            <span class="value">{{data?.value}}</span>
          </div>
        </div>
      </ng-container>
      <div class="more-legends">
        <span *ngIf="currentChartConfig?.customLegend?.length &gt; 4">more<span class="pl-1"><i class="fas fa-chevron-right"></i></span></span>
        <div class="card tooltip-legend-card">
          <div class="card-body">
            <ng-container *ngFor="let data of currentChartConfig?.customLegend; let i=index;">
              <div class="row pt-2" *ngIf="i &gt; 3">
                <div class="status-bar" [style.border-left-color]="data?.color">
                  <span class="title">{{data?.name}}</span>
                  <span class="value">{{data?.value}}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>