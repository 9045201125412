<div [ngClass]="currentThemeName" style="height: 100%;">
  <div class="row pt-1">
    <div class="chart-title text-center swipe-buttons col-12" *ngIf="currentChartConfig.widgetTitle != 'DEFAULT_GROUP'">
      <a class="control-button" (click)="setChartContent(currentPosition, 'decrease')" *ngIf="chartData.length &gt; 1">
        <i class="fa fa-angle-left"></i>
      </a>
      <span class="pl-2 pr-2">{{currentChartConfig.widgetTitle}}</span>
      <a class="control-button" (click)="setChartContent(currentPosition, 'increase')" *ngIf="chartData.length &gt; 1">
        <i class="fa fa-angle-right"></i>
      </a>
    </div>
  </div>
  <div class="row pt-1" [style.height]="this.currentChartConfig.paging == true ? '87%' :'100%'">
    <div class="col-12 pl-1 pr-1" style="height: 100%; overflow-y: auto;">
      <table datatable [dtOptions]="dtOptions" class="table dtable-format" *ngIf="drawTable">
        <thead>
          <tr>
            <th *ngFor="let header of currentChartConfig?.columns">{{header.data}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let body of currentChartConfig?.data">
            <td *ngFor="let header of currentChartConfig?.columns">
              <app-datatable-rows [columnData]="body[header.data]" [columnType]="header.type"></app-datatable-rows>
              <!-- <div [innerHTML]="body[header.data] | sanitizeHtml"></div> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>