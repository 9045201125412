import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';

/**
 * Auth gaurd to protect pages from being accessible without the user being logged into the system
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  /**
   * Construct for all the instances of services used in class
   * @param router Instance of router service
   * @param notificationService Instance of notification service
   * @param authService Instance of auth service
   */
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  /**
   * Implementation of canActivate function. In case the user is present and the expiry time is still there, true is returned else false
   */
  canActivate() {
    const user = this.authService.getCurrentUser();
    if (user && moment().isBefore(user.expiration)) {
      if (moment() < moment(user.expiration)) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
