import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DatatableConfig } from 'src/app/core/interfaces/datatable-config';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';


interface DataTableWithImage {
  initials?: string,
  fullName?: string,
  count?: number,
  details?: Array<Object>
}

@Component({
  selector: 'app-data-table-image-icon-view',
  templateUrl: './data-table-image-icon-view.component.html',
  styleUrls: ['./data-table-image-icon-view.component.scss']
})
export class DataTableImageIconViewComponent implements OnInit {

  @Input() chartData: Array<DatatableConfig> = [];
  @Input() widgetHeight: string = '';
  currentThemeName: String = '';
  dtOptions: Object = {};
  dtableWithImage: Array<DataTableWithImage> = [];
  
  constructor(
    private themeService: ThemeSetterService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    this.chartData[0].data.forEach(data => {
      let tmpDtableWithImage: DataTableWithImage = {};
      tmpDtableWithImage.details = [];
      // let tempObjArray: Array<Object> = [];
      let keyList = Object.keys(data);
      keyList.forEach(keyData => {
        if (keyData == 'Count') { tmpDtableWithImage.count = data[keyData];}
        else if(keyData == 'Name') {
          let replaceDot = /\./gi;
          let replaceHyphen = /-/gi;
          let replaceUnderscore = /_/gi;
          let finalName: string = data[keyData].replace(replaceDot, ' ').replace(replaceHyphen, ' ').replace(replaceUnderscore, ' ');
          tmpDtableWithImage.initials = this.getInitials(data[keyData]);
          tmpDtableWithImage.fullName = finalName;
        } else {
          tmpDtableWithImage.details.push({'key':keyData, 'value':data[keyData]})
        }
      });
      
      // tmpDtableWithImage.details.push(tempObjArray);
      this.dtableWithImage.push(tmpDtableWithImage);
    });

    this.dtOptions = {
      paging: this.chartData[0].paging,
      searching: this.chartData[0].searching,
      // ordering: this.chartData[0].ordering,
      info: false,
      iDisplayLength: this.chartData[0].displayLength,
      columnDefs: this.chartData[0].columnDefs
    }
  }

  getInitials(fullName: string) {
    let replaceDot = /\./gi;
    let replaceHyphen = /-/gi;
    let replaceUnderscore = /_/gi;
    let finalName = fullName.replace(replaceDot, ' ').replace(replaceHyphen, ' ').replace(replaceUnderscore, ' ');

    let tmp: Array<string> = finalName.split(' ');
    let tmpInitial: string = tmp[0].substring(0,1);
    if(tmp.length > 1) {
      tmpInitial = tmpInitial + tmp[1].substring(0, 1);
    }
    return tmpInitial.toUpperCase();
  }


  loadMoreDetails(path: string) {
    
  }

}
