import { Component, OnInit, Input } from '@angular/core';
import { LineBarChartConfig } from 'src/app/core/interfaces/line-bar-chart-config';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-line-bar-chart',
  templateUrl: './line-bar-chart.component.html',
  styleUrls: ['./line-bar-chart.component.scss']
})
export class LineBarChartComponent implements OnInit {

  @Input() chartData: Array<LineBarChartConfig> = [];
  @Input() widgetHeight: string = '';
  currentThemeName: String = '';
  trimLegends: boolean = true;
  currentPosition: number = 0;
  currentChartConfig: LineBarChartConfig = {};
  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; })

    for (let i = 0; i < this.chartData.length; i++) {
      this.chartData[i].legend.type = 'scroll';
	  this.chartData[i].legend.bottom = null;
	  this.chartData[i].legend.padding = [0, 45, 15, 15];
	  this.chartData[i].legend.icon = 'rect';
	  this.chartData[i].legend.textStyle = {};
	  this.chartData[i].legend.textStyle.fontSize = 10;
	  this.chartData[i].legend.textStyle.color = '#5e6e82';
      // this.chartData[i].grid = {};
      this.chartData[i].grid.right = 10;
      this.chartData[i].grid.left = 5;
      this.chartData[i].grid.top = 20;
      this.chartData[i].grid.bottom = 10;
      this.chartData[i].grid.show = false;

      /* X-Axis Settings */
      this.chartData[i].xAxis[0].axisLine = {};
      this.chartData[i].xAxis[0].axisLine.show = false;
      this.chartData[i].xAxis[0].axisLine.lineStyle = {};
      this.chartData[i].xAxis[0].axisLine.lineStyle.color = '#90a4ae';
      this.chartData[i].xAxis[0].axisLabel = {};
      this.chartData[i].xAxis[0].axisLabel.fontFamily = 'Roboto';
      this.chartData[i].xAxis[0].axisLabel.fontSize = 10;
      this.chartData[i].xAxis[0].axisTick = {};
      this.chartData[i].xAxis[0].axisTick.show = false;
      this.chartData[i].xAxis[0].splitLine = {};
      this.chartData[i].xAxis[0].splitLine.lineStyle = {};
      this.chartData[i].xAxis[0].splitLine.lineStyle.type = 'dashed';

      /* Y-Axis Settings */
      this.chartData[i].yAxis[0].axisLine = {};
      this.chartData[i].yAxis[0].axisLine.show = false;
      this.chartData[i].yAxis[0].axisLine.lineStyle = {};
      this.chartData[i].yAxis[0].axisLine.lineStyle.color = '#90a4ae';
      this.chartData[i].yAxis[0].axisLabel = {};
      this.chartData[i].yAxis[0].axisLabel.fontFamily = 'Roboto';
      this.chartData[i].yAxis[0].axisLabel.fontSize = 10;
      this.chartData[i].yAxis[0].axisTick = {};
      this.chartData[i].yAxis[0].axisTick.show = false;
      this.chartData[i].yAxis[0].splitLine = {};
      this.chartData[i].yAxis[0].splitLine.lineStyle = {};
      this.chartData[i].yAxis[0].splitLine.lineStyle.type = 'dashed';
      this.chartData[i].yAxis[0].splitLine.lineStyle.color = '#e2e2e2';

      if (this.chartData[i].yAxis.length > 1) {
        this.chartData[i].yAxis[1].axisLine = {};
        this.chartData[i].yAxis[1].axisLine.show = false;
        this.chartData[i].yAxis[1].axisLine.lineStyle = {};
        this.chartData[i].yAxis[1].axisLine.lineStyle.color = '#90a4ae';
        this.chartData[i].yAxis[1].axisLabel = {};
        this.chartData[i].yAxis[1].axisLabel.fontFamily = 'Roboto';
        this.chartData[i].yAxis[1].axisLabel.fontSize = 10;
        this.chartData[i].yAxis[1].axisTick = {};
        this.chartData[i].yAxis[1].axisTick.show = false;
        this.chartData[i].yAxis[1].splitLine = {};
        this.chartData[i].yAxis[1].splitLine.lineStyle = {};
        // this.chartData[i].yAxis[0].splitLine.lineStyle.type = 'dashed';
        this.chartData[i].yAxis[1].splitLine.lineStyle.color = '#e2e2e2';
      }
      this.chartData[i].tooltip.backgroundColor = '#fff';
      this.chartData[i].tooltip.borderColor = '#b6c1d2';
      this.chartData[i].tooltip.borderWidth = 1;
      this.chartData[i].tooltip.padding = [7, 10];
      this.chartData[i].tooltip.textStyle = {};
      this.chartData[i].tooltip.textStyle.color = '#5e6e82';
      this.chartData[i].tooltip.textStyle.fontWeight = 'bold';
      this.chartData[i].tooltip.textStyle.fontSize = 11;
      this.chartData[i].tooltip.transitionDuration = 0;
      this.chartData[i].tooltip.triggerOn = "mousemove";
      for (let j = 0; j < this.chartData[i].series.length; j++) {
        this.chartData[i].series[j].barMaxWidth = '10px';
        this.chartData[i].series[j].barGap = 0;
        this.chartData[i].series[j].itemStyle.barBorderRadius = 0;
        this.chartData[i].series[j].lineStyle = {};
        this.chartData[i].series[j].lineStyle.width = 2;
        // this.chartData[i].series[j].lineStyle.shadowBlur = { shadowColor: 'rgba(0, 0, 0, 0.5)', shadowBlur: 3 };
        // this.chartData[i].series[j].label = {};
        // this.chartData[i].series[j].label.show = true;
        // this.chartData[i].series[j].label.position = 'top';
        // this.chartData[i].series[j].label.fontFamily = 'Roboto';
        // this.chartData[i].series[j].label.fontSize = 10;
      }
    }
    this.setChartContent(0, 'decrease');
  }

  setChartContent(cPosition: number, action: string) {
    if (this.chartData.length > 1) {
      if (cPosition <= 0) {
        if (action == 'decrease') {
          this.currentPosition = 0;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      } else if (cPosition >= this.chartData.length - 1) {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.chartData.length - 1;
        }
      } else {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      }
      this.currentChartConfig = {};
      this.currentChartConfig = this.chartData[this.currentPosition];
    } else {
      this.currentChartConfig = this.chartData[0];
    }
  }

}
