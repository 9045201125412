import { Component, OnInit, Input } from '@angular/core';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.scss']
})
export class ProgressCardComponent implements OnInit {

  @Input() dataObj: Object;
  sparklineData: Array<number> = [];
  currentThemeName: String = '';
  
  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    let tmp = this.dataObj['trendLine'].split(',');
    tmp.forEach(data => {
      this.sparklineData.push(+data);
    })
  }

}
