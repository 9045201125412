import { Component, OnInit, Input } from '@angular/core';
import { PieChartConfig } from 'src/app/core/interfaces/pie-chart-config';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-pie-doughnut-chart',
  templateUrl: './pie-doughnut-chart.component.html',
  styleUrls: ['./pie-doughnut-chart.component.scss']
})
export class PieDoughnutChartComponent implements OnInit {

  @Input() chartData: Array<PieChartConfig> = [];
  @Input() widgetHeight: string = '';
  currentThemeName: String = '';
  currentPosition: number = 0;
  currentChartConfig: PieChartConfig = {};

  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; })
    for (let i = 0; i < this.chartData.length; i++) {
      if (this.chartData[i].color == null || this.chartData[i].color == undefined) {
        this.chartData[i].color = ['#1976D2', '#00d27a', '#F57C00', '#f44336', '#7B1FA2', '#AFB42B', '#616161', '#2c7be5', '#FFA000', '#27bcfd']
      }
      this.chartData[i].legend = null;
      this.chartData[i].tooltip.backgroundColor = '#fff';
      this.chartData[i].tooltip.borderColor = '#b6c1d2';
      this.chartData[i].tooltip.borderWidth = 1;
      this.chartData[i].tooltip.padding = [7, 10];
      this.chartData[i].tooltip.textStyle = { "color": '#5e6e82', "fontWeight": 'bold', "fontSize": 11 };
      this.chartData[i].tooltip.transitionDuration = 0;
      this.chartData[i].tooltip.triggerOn = "mousemove";
      this.chartData[i].series[0].labelLine = null;
      this.chartData[i].series[0].hoverAnimation = false;
      this.chartData[i]['customLegend'] = [];

      for (let j = 0; j < this.chartData[i].series[0].data.length; j++) {
        this.chartData[i]['customLegend'].push({
          name: this.chartData[i].series[0].data[j].name,
          value: this.chartData[i].series[0].data[j].value,
          order: this.chartData[i].series[0].data[j].order,
          color: this.chartData[i].color[j]
        });
      }
    }
    this.setChartContent(0, 'decrease');
  }

  setChartContent(cPosition: number, action: string) {
    if (this.chartData.length > 1) {
      if (cPosition <= 0) {
        if (action == 'decrease') {
          this.currentPosition = 0;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      } else if (cPosition >= this.chartData.length - 1) {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.chartData.length - 1;
        }
      } else {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      }
      this.currentChartConfig = {};
      this.currentChartConfig = this.chartData[this.currentPosition];
    } else {
      this.currentChartConfig = this.chartData[0];
    }
  }

}
