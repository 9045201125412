<div class="login-container">
  <div class="card">
    <a routerLink="/home"><img class="card-img-top" src="assets/img/logo-dark.png" alt="App360"></a>
    <div class="card-body">
      <div class="card-error-notification" *ngIf="!loginCallInProgress && errorMessage != ''">{{errorMessage}}</div>
      <form class="text-center" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <a class="img-user"><img [src]="user.avatar" alt=""></a>
        <h3 class="user-name">{{user.fullName | titlecase}}</h3>
        <div class="form-group">
          <input type="password" class="form-control" placeholder="Password" formControlName="userPassword"
            [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors}" required>
          <div *ngIf="submitted && f.userPassword.errors" class="invalid-feedback">
            <div *ngIf="f.userPassword.errors.required">Password is required</div>
            <div *ngIf="f.userPassword.errors.minlength">Password should be minimum 8 characters</div>
            <div *ngIf="f.userPassword.errors.maxlength">Password should be maximum 20 characters</div>
          </div>
        </div>
        <div class="login-footer d-flex">
          <button (click)="logout()" class="btn btn-default">
            Sign Out
          </button>
          <button type="submit" class="btn btn-default ml-auto" [disabled]="loginCallInProgress">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
              *ngIf="loginCallInProgress"></span>
            Unlock
          </button>
        </div>
      </form>
    </div>
  </div>
</div>