import { Component, Input, OnInit } from '@angular/core';
import { BigPictureDataObject } from 'src/app/core/interfaces/big-picture-data-object';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-cards-view',
  templateUrl: './cards-view.component.html',
  styleUrls: ['./cards-view.component.scss']
})
export class CardsViewComponent implements OnInit {

  @Input() chartData: Array<Object> = [];
  @Input() widgetHeight: string = '';
  currentThemeName: String = '';

  cardsData: Array<Array<BigPictureDataObject>> = [];

  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    this.cardsData = this.chartData[0]['data'];
  }

}
