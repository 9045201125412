<div [ngClass]="currentThemeName" style="min-height: 100%;">
  <h4 class="title text-center" *ngIf="dataObj.widgetTitle != 'DEFAULT_GROUP'">{{dataObj.widgetTitle}}</h4>
  <table class="label-table">
    <tbody>
      <tr *ngFor="let data of dataObj.data">
        <td class="text-label">
          <div [innerHTML]="data.lebel"></div>
        </td>
        <td class="text-right">
          <div class="badge" [ngClass]="data.badgeClass">{{data.value}}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>