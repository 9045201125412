import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  currentThemeName: String        = '';
  userImage: string               = this.authService.getCurrentUser().avatar || '';
  userFullname: string            = this.authService.getCurrentUser().fullName || 'No Name';
  changePasswordForm: FormGroup   = new FormGroup({});
  submitted: boolean              = false;
  errorMessage: string            = '';
  callInProgress: boolean         = false;
  newPassword: string             = '';

  constructor(
    private authService: AuthService,
    private formBuild:  FormBuilder,
    private apiService: ApiCallService,
    private themeService: ThemeSetterService,
    private router: Router,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    this.changePasswordForm = this.formBuild.group({
      currentPass: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      loginId: [this.authService.getCurrentUser().email],
      newPass: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      newPassConfirm: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]}, { validators: this.MatchPassword });
    this.onChanges();
  }

  onChanges(): void {
    this.changePasswordForm.get('newPass')?.valueChanges.subscribe(val => {
      this.newPassword = val;
    });
  }

  get f() { return this.changePasswordForm.controls; }

  MatchPassword(control: AbstractControl) {
    let password = control.get('newPass')?.value;
    let confirmpassword = control.get('newPassConfirm')?.value;
    if (password != confirmpassword) {
      control.get('newPassConfirm')?.setErrors({ ConfirmPassword: true });
      return true;
    } else {
      return null
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.callInProgress = true;
    let formObj = this.changePasswordForm.getRawValue();
    formObj['currentPass'] = this.base64Encode(formObj['currentPass']);
    formObj['newPass'] = this.base64Encode(formObj['newPass']);
    formObj['newPassConfirm'] = this.base64Encode(formObj['newPassConfirm']);
    // this.logger.debug('Change password request form: ', '/api/v3/user/~replaceMe~'.replace('~replaceMe~', this.authService.getCurrentUser().userId || '0'));
    this.apiService.put('/api/v3/user/~replaceMe~'.replace('~replaceMe~', this.authService.getCurrentUser().userId || '0'), formObj, 'TEXT')
      .subscribe(
        resp => {
          this.logger.debug('Response from password change: ', resp);
          if (resp == 'Details Updated Successfully') {
            this.callInProgress = false;
            this.closeModal();
            this.authService.logout(false);
            this.router.navigate(['/landing']);
          } else {
            this.errorMessage = resp;
            this.callInProgress = false;
          }
        },
        error => {
          this.logger.error('Error returned from password change: ', error.error);
          this.errorMessage = error.error;
          this.callInProgress = false;
        }
      );
  }

  private base64Encode(val: string): string {
    return btoa(val);
  }

  closeModal() {
    $("#changePasswordModal").modal('hide');
  }

}
