import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import { NotificationAlert } from 'src/app/core/interfaces/notification-alert';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notifications-card',
  templateUrl: './notifications-card.component.html',
  styleUrls: ['./notifications-card.component.scss']
})
export class NotificationsCardComponent implements OnInit {

  loadDataInterval: any;
  errorMessage: string = '';
  lastUpdateTime: string = '';
  notifications: NotificationAlert = {};

  constructor(
    private apiService: ApiCallService,
    private authService: AuthService,
    private logger: NGXLogger,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadDataFromUrl();
  }

  loadDataFromUrl(): void {
    this.apiService.get("/getjson/11").subscribe(
      resp => {
        if (resp.data.length > 0) {
          this.notifications = resp;
          this.notifications.data.forEach(alert => {
            if (alert.isAlert && alert.isActive) {
              if (alert.severity == 1) {
                this.toastr.error(alert.message, 'Critical!!!');
              } else if (alert.severity == 2) {
                this.toastr.warning(alert.message, 'Attention!!!');
              } else if (alert.severity == 3) {
                this.toastr.info(alert.message, 'Info');
              } else if (alert.severity == 4) {
                this.toastr.success(alert.message, 'Status');
              }
            }
          })
        } else {
          this.errorMessage = 'No notifications!!! Enjoy your day.'
        }
        this.lastUpdateTime = moment().calendar();
      },
      error => {
        this.errorMessage = 'Error fetching notifications. Errorcode ' + error.status;
        this.lastUpdateTime = moment().calendar();
      }
    ).unsubscribe;

    // if (this.authService.getCurrentUser() && moment().isBefore(this.authService.getCurrentUser().expiration)) {
    //   this.loadDataInterval = setTimeout(() => this.loadDataFromUrl(), 20000);
    // }
  }

  stopAutoRefresh(): void {
    clearInterval(this.loadDataInterval);
  }

}
