<div class="d-flex container-body" [ngClass]="currentThemeName">
    <div class="sidenav" [ngClass]="showSidebar == true? 'active': ''">
        <span class="pl-3" (click)="toggleSidenav()" style="cursor: pointer;" *ngIf="showSidebar == false">
            <i class="fas fa-angle-double-right"></i>
        </span>
        <span class="pl-3 pr-3 d-flex" (click)="toggleSidenav()" style="cursor: pointer;" *ngIf="showSidebar">
            <img src="assets/img/logo-light.png" class="d-inline-block align-top" alt="">
            <div class="icon ml-auto">
                <i class="fas fa-angle-double-left"></i>
            </div>
        </span>
        <div class="page-links">
            <div class="d-flex" *ngFor="let page of widgetPageList"
                [ngClass]="currentPageId == page.pageId? 'active':''">
                <a class="page-icons" (click)="setPageId(page.pageId)">
                    <div [innerHTML]="page.pageIcon"></div>
                </a>
                <div class="tooltip-text"><span>{{page.pageName}}</span></div>
                <div class="panel" *ngIf="showSidebar">
                    <div class="panel-heading page-details">
                        <div class="heading" data-toggle="collapse" [attr.href]="'#collapse' + page.pageId">
                            <span class="title">{{page.pageName}}</span>
                            <div class="icon ml-auto">
                                <i class="fas fa-angle-right"></i>
                            </div>
                        </div>
                    </div>
                    <div [id]="'collapse' + page.pageId" class="panel-collapse collapse widget-list">
                        <ul>
                            <li class="mt-1 mb-1" *ngFor="let widget of page.widgetList">{{widget}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-container">
        <nav class="navbar navbar-light navbar-expand-md">
            <a class="navbar-brand" href="#">
                <img src="assets/img/logo-light.png" class="d-inline-block align-top" alt="">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
                aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarToggler">
                <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                    <li class="nav-item">
                        <a class="option" data-toggle="tooltip" data-placement="bottom" title="Save as Pdf"
                            (click)="openPDF()"><i class="far fa-file-pdf"></i></a>
                    </li>
                    <li class="nav-item dropdown notifications-menu">
                        <a class="option" id="notificationDropdown" data-toggle="dropdown"><i
                                class="far fa-bell"></i></a>

                        <div aria-labelledby="notificationDropdown" class="dropdown-menu dropdown-menu-right"
                            style="padding: 0;">
                            <app-notifications-card></app-notifications-card>
                        </div>

                    </li>
                    <li class="nav-item dropdown profile-menu">
                        <a id="profileDropdown" data-toggle="dropdown">
                            <div class="avatar-circle">
                                <span class="initials">N</span>
                              </div>
                            <!-- <img [src]="user.avatar" alt=""> -->
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="profileDropdown">
                            <div class="profile-header text-center">
                                <img [src]="user.avatar" alt="">
                                <p class="pt-2">{{user.fullName | titlecase}}</p>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-header">Select View</div>
                            <ng-container *ngFor="let role of userRoles">
                                <a class="dropdown-item" [ngClass]="user.currentRole == role ? 'disabled':''" style="cursor: pointer;"
                                    (click)="changeContext(role)">{{getRoleDefinition(role)}}</a>
                            </ng-container>
                            <div class="dropdown-divider"></div>
                            <!-- <a class="dropdown-item" style="cursor: pointer;">My Profile</a> -->
                            <a class="dropdown-item" style="cursor: pointer;" (click)="showChangePasswordModal()">Change
                                Password</a>
                            <a (click)="resetLayout()" class="dropdown-item" style="cursor: pointer;">Reset Layout</a>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-header">Change Theme</div>
                            <div class="dropdown-item text-center">
                                <span (click)="setTheme('theme-blue')" class="dot dot-blue"
                                    style="cursor: pointer;"></span>
                                <span (click)="setTheme('theme-red')" class="dot dot-red"
                                    style="cursor: pointer;"></span>
                                <span (click)="setTheme('theme-amber')" class="dot dot-amber"
                                    style="cursor: pointer;"></span>
                                <span (click)="setTheme('theme-green')" class="dot dot-green"
                                    style="cursor: pointer;"></span>
                                <span (click)="setTheme('theme-dark')" class="dot dot-dark"
                                    style="cursor: pointer;"></span>
                            </div>
                            <div class="dropdown-divider"></div>
                            <a routerLink="/locked" class="dropdown-item" style="cursor: pointer;">Lock Screen</a>
                            <a (click)="logout()" class="dropdown-item text-danger" style="cursor: pointer;">Sign
                                Out</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>

        <router-outlet class="router-content"></router-outlet>

        <div class="footer-container container-fluid">
            <div class="d-flex footer-content">
                <div class="mr-auto">
                    <span>© {{currentYear}} HCL Technologies. Powered By DNA.</span>
                </div>
                <div class="ml-auto">
                    <span>v1.4.0 released on 03-05-2022 13:39 IST</span>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay" [ngClass]="showSidebar == true? 'active': ''" (click)="toggleSidenav()"></div>
</div>

<app-change-password></app-change-password>