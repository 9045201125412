<div [ngClass]="currentThemeName" style="height: 100%;">
  <div class="row" style="height: 100%;">
    <!-- <ng-container > -->
    <div
      [ngClass]="[cardsData[0].length &lt;= 4 ? 'col-6 pl-1 pr-1':'col-4 pl-1 pr-1', (cardsData[0].length &lt;= 4 && i &gt; 1 )? 'pt-2': (cardsData[0].length &gt; 4 && i &gt; 2 )? 'pt-2': '']"
      *ngFor="let card of cardsData[0]; let i=index;">
      <!-- <div class="col-6 pl-1 pr-1" [ngClass]="i &gt; 1? 'pt-2':''" *ngFor="let card of cardsData[0]; let i=index;"> -->
      <div class="card status-card h-100">
        <div class="card-body">
          <div class="title-value">{{card.value}}</div>
          <div class="title-label">{{card.label}}</div>
        </div>
      </div>
    </div>
    <!-- </ng-container> -->
  </div>
</div>