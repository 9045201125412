<div [ngClass]="currentThemeName" style="height: 100%;">
  <table datatable [dtOptions]="dtOptions" class="table dtable-format" *ngIf="drawTable" style="height: 100%; overflow-y: auto;">
    <thead>
      <tr>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of currentChartConfig.data">
        <td>
          <div class="title">{{data.TeamName | titlecase}}</div>
          <span class="sub-title">{{data.MemberCount}} Members</span>
        </td>
        <td class="count-overview">
          <div>{{data.TotalSP}}</div>
          <span>Story Points</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>