import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { JwtToken } from '../interfaces/jwt-token';
import { LoginResponse } from '../interfaces/login-response';

/**
 * Single point authentication service to handle login, logout and user details from single place.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /**
     * Contructs used with-in this class
     * @param http Http client instance
     * @param logger Ngx-logger instance
     */
    constructor(
        private http: HttpClient,
        private logger: NGXLogger
    ) { }

    /**
     * Function to store user details once the login call is successfull
     * @param jwtToken The jwt token returned from the authentication service
     * @param rememberMe Whether to store the data in local storage or session storage
     */
    login(loginResponse: LoginResponse, rememberMe: boolean) {

        let decryptToken: JwtToken = JSON.parse(atob(loginResponse.token.substring(loginResponse.token.indexOf('.') + 1, loginResponse.token.lastIndexOf('.')).replace('_', '/')));

        if (rememberMe) {
            localStorage.setItem('currentUser', btoa(JSON.stringify(
                {
                    token: loginResponse.token,
                    role: [decryptToken.auth].join(),
                    email: decryptToken.user,
                    alias: decryptToken.sub,
                    avatar: decryptToken.avatar,
                    expiration: moment.unix(decryptToken.exp),
                    fullName: decryptToken.sub,
                    acls: loginResponse.acl,
                    currentRole: decryptToken["def-role"],
                    userId: decryptToken['user-id'].toString()
                })));
        } else {
            sessionStorage.setItem('currentUser', btoa(JSON.stringify(
                {
                    token: loginResponse.token,
                    role: [decryptToken.auth].join(),
                    email: decryptToken.user,
                    alias: decryptToken.sub,
                    avatar: decryptToken.avatar,
                    expiration: moment.unix(decryptToken.exp),
                    fullName: decryptToken.sub,
                    acls: loginResponse.acl,
                    currentRole: decryptToken["def-role"],
                    userId: decryptToken['user-id'].toString()
                })));
        }
    }

    /**
     * Logout the user and clear local and session storage
     */
    logout(clearAll: boolean) {
        if (clearAll) {
            localStorage.clear();
            sessionStorage.clear()
        } else {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('pageLayout');
            sessionStorage.removeItem('currentUser');
            sessionStorage.removeItem('pageLayout');
        }
    }

    /**
     * Get the current user details out from the local or session storage wherever it is stored
     */
    getCurrentUser(): any {
        if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
            return JSON.parse(atob(localStorage.getItem('currentUser')));
        } else if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
            return JSON.parse(atob(sessionStorage.getItem('currentUser')));
        } else {
            return null;
        }
    }

    setCurrentSelection(pageId: number, aclId: number) {
        if (sessionStorage.getItem('currentUser') != undefined && sessionStorage.getItem('currentUser') != null) {
            let tempObject: Object = {};
            tempObject['pageId'] = pageId;
            tempObject['aclId'] = aclId;
            sessionStorage.setItem('currentSelection', JSON.stringify(tempObject));
        } else if (localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != null) {
            let tempObject: Object = {};
            tempObject['pageId'] = pageId;
            tempObject['aclId'] = aclId;
            // localStorage.removeItem('currentSelection');
            localStorage.setItem('currentSelection', JSON.stringify(tempObject));
        }
    }

    getCurrentSelection(): any {
        if (localStorage.getItem('currentSelection') != undefined && localStorage.getItem('currentSelection') != null) {
            return JSON.parse(localStorage.getItem('currentSelection'));
        } else if (sessionStorage.getItem('currentSelection') != undefined && sessionStorage.getItem('currentSelection') != null) {
            return JSON.parse(sessionStorage.getItem('currentSelection'));
        } else {
            return null;
        }
    }
}
