<div id='main' class="dashboard-container" [ngClass]="currentThemeName">
  <div class="row top-bar">
    <div class="col-sm-12 col-md-6">
      <ol class="breadcrumb">
        <li class="breadcrumb-item dropdown project-selection">
          <!-- <div class="dropdown project-selection"> -->
          <span class="dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">{{currentProject.name}}</span>
          <div class="dropdown-menu">
            <a class="dropdown-item" [ngClass]="currentProject.id == acl.id? 'active':''"
              (click)="setCurrentProject(acl?.id)" *ngFor="let acl of acls">{{acl.name}}</a>
          </div>
          <!-- </div> -->
        </li>
        <li class="breadcrumb-item" [ngClass]="isLanding == true? 'active':''" style="cursor: pointer;"><a
            (click)="gotoHome()">Home</a>
        </li>
        <li class="breadcrumb-item active" *ngIf="isLanding == false">{{pageTitle}}</li>
      </ol>
    </div>
    <div class="col-sm-12 col-md-6" *ngIf="globalSearchList?.data?.length &gt; 0" style="padding: 0;">
      <ng-container *ngIf="globalSearchList.data.length &lt;= 15">
        <div class="dropdown global-filter">
          <span class="dropdown-toggle" type="button" id="globalFilter" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">Data Filtered On > {{gid.value}}</span>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" (click)="setGlobalId(gFilter)" [ngClass]="gid.key == gFilter.key ? 'active':''"
              *ngFor="let gFilter of globalSearchList.data">{{gFilter.value}}</a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="globalSearchList.data.length &gt; 15">
        <ng-autocomplete [data]="globalSearchList.data" [searchKeyword]="keyword" [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate" [minQueryLength]="1" 
          (selected)="setGlobalId($event)" required>
        </ng-autocomplete>
        <ng-template #itemTemplate let-item>
          <span [innerHTML]="item.value"></span>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="pt-3"></div>
  <div class="row equal ml-auto mr-auto" *ngFor="let data of rowData; let i= index" [ngClass]="i &gt; 0? 'pt-3':''">
    <div [ngClass]="data.layoutName == '12_0'? 'col-12': 
                      data.layoutName == '6_6'? 'col-md-6': 
                      data.layoutName == '4_4_4'? 'col-md-4': 
                      data.layoutName == '3_3_3_3'? 'col-md-3': 
                      data.layoutName == '9_3'? 'col-md-9': 
                      data.layoutName == '3_9'? 'col-md-3': 
                      data.layoutName == '6_3_3'? 'col-md-6': 
                      data.layoutName == '3_3_6'? 'col-md-3': 
                      data.layoutName == '8_4'? 'col-md-8': 
                      data.layoutName == '4_8'? 'col-md-4': 
                      data.layoutName == '7_5'? 'col-md-7': 
                      data.layoutName == '5_7'? 'col-md-5': 'col-12'" class="col-sm-12 pl-1 pr-2" *ngIf="data.layoutName == '12_0' ||
                  data.layoutName == '6_6' || 
                  data.layoutName == '4_4_4' || 
                  data.layoutName == '3_3_3_3' || 
                  data.layoutName == '9_3' || 
                  data.layoutName == '3_9' || 
                  data.layoutName == '6_3_3' || 
                  data.layoutName == '3_3_6' || 
                  data.layoutName == '8_4' || 
                  data.layoutName == '4_8' || 
                  data.layoutName == '7_5' || 
                  data.layoutName == '5_7'">
      <ng-container *ngFor="let item of data.widgets">
        <div class="card h-100" *ngIf="item.columnNumber == 1" [ngClass]="item.widgetOrder &gt; 1? 'mt-3':''" [style.min-height]="item.widgetHeight">
          <app-type-one-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-ONE'"></app-type-one-min>
          <app-type-two-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-TWO'"></app-type-two-min>
          <app-type-three-min [widgetId]="item.widgetId" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-THREE'"></app-type-three-min>
        </div>
      </ng-container>
    </div>
    <div [ngClass]="data.layoutName == '6_6'? 'col-md-6': 
          data.layoutName == '4_4_4'? 'col-md-4': 
          data.layoutName == '3_3_3_3'? 'col-md-3': 
          data.layoutName == '9_3'? 'col-md-3': 
          data.layoutName == '3_9'? 'col-md-9': 
          data.layoutName == '6_3_3'? 'col-md-3': 
          data.layoutName == '3_3_6'? 'col-md-3': 
          data.layoutName == '8_4'? 'col-md-4': 
          data.layoutName == '4_8'? 'col-md-8': 
          data.layoutName == '7_5'? 'col-md-5': 
          data.layoutName == '5_7'? 'col-md-7': 'col-12'" class="col-sm-12 pl-1 pr-2" *ngIf="data.layoutName == '6_6' || 
                  data.layoutName == '4_4_4' || 
                  data.layoutName == '3_3_3_3' || 
                  data.layoutName == '9_3' || 
                  data.layoutName == '3_9' || 
                  data.layoutName == '6_3_3' || 
                  data.layoutName == '3_3_6' || 
                  data.layoutName == '8_4' || 
                  data.layoutName == '4_8' || 
                  data.layoutName == '7_5' || 
                  data.layoutName == '5_7'">
      <ng-container *ngFor="let item of data.widgets">
        <div class="card h-100" *ngIf="item.columnNumber == 2" [ngClass]="item.widgetOrder &gt; 1? 'mt-3':''" [style.min-height]="item.widgetHeight">
          <app-type-one-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-ONE'"></app-type-one-min>
          <app-type-two-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-TWO'"></app-type-two-min>
          <app-type-three-min [widgetId]="item.widgetId" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-THREE'"></app-type-three-min>
        </div>
      </ng-container>
    </div>
    <div [ngClass]="data.layoutName == '4_4_4'? 'col-md-4': 
          data.layoutName == '3_3_3_3'? 'col-md-3':
          data.layoutName == '6_3_3'? 'col-md-3': 
          data.layoutName == '3_3_6'? 'col-md-6':'col-12'" class="col-sm-12 pl-1 pr-2" *ngIf="data.layoutName == '4_4_4' || 
                  data.layoutName == '3_3_3_3' ||
                  data.layoutName == '6_3_3' || 
                  data.layoutName == '3_3_6'">
      <ng-container *ngFor="let item of data.widgets">
        <div class="card h-100" *ngIf="item.columnNumber == 3" [ngClass]="item.widgetOrder &gt; 1? 'mt-3':''" [style.min-height]="item.widgetHeight">
          <app-type-one-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-ONE'"></app-type-one-min>
          <app-type-two-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-TWO'"></app-type-two-min>
          <app-type-three-min [widgetId]="item.widgetId" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-THREE'"></app-type-three-min>
        </div>
      </ng-container>
    </div>
    <div [ngClass]="data.layoutName == '3_3_3_3'? 'col-md-3': 'col-12'" class="col-sm-12 pl-1 pr-1" *ngIf="data.layoutName == '3_3_3_3'">
      <ng-container *ngFor="let item of data.widgets">
        <div class="card h-100" *ngIf="item.columnNumber == 4" [ngClass]="item.widgetOrder &gt; 1? 'mt-3':''" [style.min-height]="item.widgetHeight">
          <app-type-one-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-ONE'"></app-type-one-min>
          <app-type-two-min [widgetId]="item.widgetId" [rowLayoutId]="data.layoutName" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-TWO'"></app-type-two-min>
          <app-type-three-min [widgetId]="item.widgetId" style="height: 100%;" *ngIf="item.widgetType == 'TYPE-THREE'"></app-type-three-min>
        </div>
      </ng-container>
    </div>
  </div>
</div>