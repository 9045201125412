<div [ngClass]="currentThemeName">
<div class="card">
  <div class="card-body">
    <div class="d-flex">
      <div class="text-highlight mr-auto">{{dataObj['value']}}%</div>
      <div class="ml-auto">
        <span class="badge badge-pill" [ngClass]="dataObj['badgeColor']">{{dataObj['badgeValue']}}%</span>
      </div>
    </div>
    <div class="w-100 pt-2">
      <div class="progress circle" style="height:6px">
        <div class="progress-bar circle" [ngClass]="dataObj['valueColor']"
          [ngStyle]="{'width': dataObj['value'] + '%'}"></div>
      </div>
    </div>
    <ngx-trend [data]="sparklineData" [gradient]="['purple', 'violet']" strokeWidth="4"></ngx-trend>
  </div>
</div>
</div>