<div class="not-found-container">
  <div class="card">
    <img class="card-img-top" src="assets/img/logo-dark.png" alt="App360">
    <div class="card-body">
      <h1>404</h1>
      <div class="lead">The page you're looking for is not found.</div>
      <p>Make sure the address is correct and that the page hasn't moved. <br> If you think this is a mistake, contact us.</p>
      <a routerLink="/landing">Back to Dashboard</a>
    </div>
  </div>
</div>