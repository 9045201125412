import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

/**
 * Class to intercept all HTTP requests and to set Authorization header in outgoing calls
 * 
 * Incase the authorization fails the user is logged out and is redirected to login page.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Constructs to be used in the class
   * @param authService Instance of auth service
   * @param router Instance of router service
   */
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  /**
   * Intercept function implementation
   * @param req Http request that is sent out
   * @param next Http handler type
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.authService.getCurrentUser();
    if (user && user.token) {
      const cloned = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + user.token)
          .set('X-CTX', user.currentRole)
      });
      return next.handle(cloned).pipe(tap(() => { }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // this.dialog.closeAll();
            this.router.navigate(['/login']);
          }
        }
      }));
    } else {
      return next.handle(req);
    }
  }
}