import { Component, OnInit, Input } from '@angular/core';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss']
})
export class ErrorViewComponent implements OnInit {

  @Input() eMessage: string = '';
  currentThemeName: String = '';
  constructor(
    private themeService: ThemeSetterService
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; })
  }

}
