import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Global error handler service to intercept and log errors inside the application
 */
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  /**
   * Constructs of modules used in class
   * @param injector Instance of injector
   */
  constructor(private injector: Injector) { }

  /**
   * Function to intercept and handle errors in application
   * @param error Error that has occured in the application
   */
  handleError(error: Error) {
    // Obtain dependencies at the time of the error
    // This is because the GlobalErrorHandler is registered first
    // which prevents constructor dependency injection
    const logger = this.injector.get(NGXLogger);

    const err = {
      message: error.message ? error.message : error.toString(),
      stack: error.stack ? error.stack : ''
    };

    // Log  the error
    logger.error(err);

    // Re-throw the error
    throw error;
  }
}
