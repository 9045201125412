<div class="login-container">
    <app-spinner *ngIf="isSocialLogin"></app-spinner>
    <div class="card"  *ngIf="!isSocialLogin">
        <a routerLink="/home"><img class="card-img-top" src="assets/img/logo-dark.png" alt="App360"></a>
        <div class="card-body">
            <div class="card-error-notification" *ngIf="!loginCallInProgress && errorMessage != ''">{{errorMessage}}
            </div>
            <form class="pull-left" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Username" formControlName="userName"
                        [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" required>
                    <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                        <div *ngIf="f.userName.errors.required">Login id is required</div>
                        <div *ngIf="f.userName.errors.email">Login id should be an email</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Password" formControlName="userPassword"
                        [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors}" required>
                    <div *ngIf="submitted && f.userPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.userPassword.errors.required">Password is required</div>
                        <div *ngIf="f.userPassword.errors.minlength">Password should be minimum 8 characters</div>
                        <div *ngIf="f.userPassword.errors.maxlength">Password should be maximum 20 characters</div>
                    </div>
                </div>
                <div class="form-group custom-control custom-checkbox checkbox-danger">
                    <input type="checkbox" class="custom-control-input" id="customCheck" formControlName="rememberMe">
                    <label class="custom-control-label" for="customCheck">Remember Me!</label>
                </div>
                <div class="login-footer d-flex">
                    <!-- <p class="align-left"><a href="">Forgot password?</a></p> -->
                    <button type="submit" class="btn btn-default align-right ml-auto" [disabled]="loginCallInProgress">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="loginCallInProgress"></span>
                        Sign In
                    </button>
                </div>
            </form>
            <p>OR</p>
            <div>
                <button class="btn btn-block" (click)="openSSOTokenService()">
                    <span>
                        <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.296/static/media/microsoft-logo.42b61fa1.svg" alt="">
                    </span>
                    <span class="pl-2">Continue with Microsoft</span>
                </button>
            </div>
        </div>
    </div>
</div>