import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DatatableConfig } from 'src/app/core/interfaces/datatable-config';
import { ThemeSetterService } from 'src/app/core/services/theme-setter.service';

@Component({
  selector: 'app-big-picture',
  templateUrl: './big-picture.component.html',
  styleUrls: ['./big-picture.component.scss']
})
export class BigPictureComponent implements OnInit {

  @Input() chartData: Array<DatatableConfig> = [];
  @Input() widgetHeight: string = '';
  currentThemeName: String = '';
  currentPosition: number = 0;
  dtOptions: Object = {};
  drawTable: boolean = false;
  currentChartConfig: DatatableConfig = {};

  constructor(
    private themeService: ThemeSetterService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.themeService.getThemeName().subscribe(themename => { this.currentThemeName = themename; });
    this.setChartContent(0, 'decrease');
  }

  setChartContent(cPosition: number, action: string) {
    this.drawTable = false;
    this.cdr.detectChanges();
    if (this.chartData.length > 1) {
      if (cPosition <= 0) {
        if (action == 'decrease') {
          this.currentPosition = 0;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      } else if (cPosition >= this.chartData.length - 1) {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.chartData.length - 1;
        }
      } else {
        if (action == 'decrease') {
          this.currentPosition = this.currentPosition - 1;
        } else {
          this.currentPosition = this.currentPosition + 1;
        }
      }
      this.currentChartConfig = {};
      this.currentChartConfig = this.chartData[this.currentPosition];
    } else {
      this.currentChartConfig = this.chartData[0];
    }

    this.dtOptions = {
      paging: this.currentChartConfig.paging,
      searching: this.currentChartConfig.searching,
      ordering: this.currentChartConfig.ordering,
      info: false,
      iDisplayLength: this.currentChartConfig.displayLength,
      columnDefs: this.currentChartConfig.columnDefs
    }
    this.drawTable = true;
    this.cdr.detectChanges();
  }

}
