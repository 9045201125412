<!-- <div [ngClass]="currentThemeName"> -->
  <div class="modal fade custom-modal" id="maxViewModal"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered" >
      <div class="modal-content details" style="height: 100%; padding: 20px;">
        <div class="d-flex header">
          <div class="ml-auto">
            <ul>
              <li data-dismiss="modal" aria-label="Close" (click)="closeModal()"><i class="fas fa-times"></i>
              </li>
            </ul>
          </div>
        </div>
        <app-spinner *ngIf="callInProgress"></app-spinner>
        <div class="" style="height: 100%; " *ngIf="callInProgress == false">
          <app-pie-doughnut-chart [chartData]="experienceResponse" *ngIf="targetComponentType == 'pie'">
          </app-pie-doughnut-chart>
          <app-line-bar-chart [chartData]="experienceResponse" *ngIf="targetComponentType == 'linebar'">
          </app-line-bar-chart>
          <app-data-table-view [chartData]="experienceResponse" *ngIf="targetComponentType == 'datatable'">
          </app-data-table-view>
          <app-data-table-image-icon-view [chartData]="experienceResponse"
            *ngIf="targetComponentType == 'datatable_image'">
          </app-data-table-image-icon-view>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->