<div [ngClass]="currentThemeName">
  <div class="progress-wrapper">
    <div class="current-value" [style.color]="dataObj.valueColor">{{dataObj.value | number:'1.0'}}%
    </div>
    <round-progress [current]="dataObj.value" [max]="100" [color]="color" [background]="'#eaeaea'" [radius]="25"
      [stroke]="7" [semicircle]="false" [rounded]="true" [clockwise]="true" [responsive]="false" [duration]="800"
      [animation]="'easeInOutQuart'" [animationDelay]="200"></round-progress>
  </div>
</div>
<!-- <round-progress [current]="85" [max]="100" [color]="'#45ccce'" [background]="'#eaeaea'" [radius]="35" [stroke]="8"
  [semicircle]="false" [rounded]="true" [clockwise]="true" [responsive]="false" [duration]="800"
  [animation]="'easeInOutQuart'" [animationDelay]="200">85</round-progress> -->