<div class="not-found-container">
    <div class="card">
        <img class="card-img-top" src="assets/img/logo-dark.png" alt="App360">
        <div class="card-body">
            <div class="lead">Raise Access Request</div>

            <form class="pull-left" [formGroup]="accessReqForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <textarea class="form-control" formControlName="requestText" rows="5"
                        placeholder="Provide details on what type of access is required."
                        [ngClass]="{ 'is-invalid': submitted && f.requestText.errors }" required></textarea>
                    <div *ngIf="submitted && f.requestText.errors" class="invalid-feedback">
                        <div *ngIf="f.requestText.errors.required">Details are required</div>
                    </div>
                </div>
                <button type="submit" class="btn button-secondary" [disabled]="callInProgress" *ngIf="showOkButton == false">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="callInProgress"></span>
                    Submit
                </button>
                <button class="btn button-secondary" *ngIf="showOkButton" (click)="redirectToLogin()">
                    Ok
                </button>
            </form>
        </div>
    </div>
</div>